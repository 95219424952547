/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import fetchTimelineLayout from './actions';

const initialState = {
  layout: null,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  extraReducers: {
    [fetchTimelineLayout.fulfilled](state, { payload }) {
      state.layout = payload;
    },
    [fetchTimelineLayout.rejected](state, {
      payload }) {
      state.error = payload;
    },
  },
});

export default layoutSlice.reducer;
