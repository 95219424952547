/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import fetchWeatherData from './actions';

const initialState = {
  data: null,
};

const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  extraReducers: {
    [fetchWeatherData.fulfilled](state, { payload }) {
      state.data = payload;
    },
    [fetchWeatherData.rejected](state, {
      payload }) {
      state.error = payload;
    },
  },
});

export default weatherSlice.reducer;
