import {
  LOADING_KPIS_DATA,
  LOADING_KPIS_DATA_SUCCESS,
  LOADING_KPIS_DATA_FAILURE,
} from './actions';

const initial = {
  isLoading: false,
  data: {
    ready: false,
    flights_planned: undefined,
    flights_status: {
      airborne: undefined,
      nonAirborne: undefined,
      unknown: undefined,
      total: undefined,
    },
  },
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case LOADING_KPIS_DATA: {
      return { ...state, isLoading: true };
    }
    case LOADING_KPIS_DATA_SUCCESS: {
      return { ...state, data: { ...action.data, ready: true }, isLoading: false };
    }
    case LOADING_KPIS_DATA_FAILURE: {
      return { ...state, data: { ...state.data, ready: false }, error: action.error, isLoading: false };
    }
    default: {
      return state;
    }
  }
};
