import { FlightListUtil, FilterUtils } from '@atfm/utils';
import {
  LOADING_FLIGHTS_LIST_DATA,
  LOADING_FLIGHTS_LIST_DATA_FAILURE,
  LOADING_FLIGHTS_LIST_DATA_SUCCESS,
  FILTERED_FLIGHTS_LIST,
  BRUSHED_FLIGHTS,
  CLEAR_BRUSH,
  CLEAR_FILTERED_FLIGHTS_LIST,
  FILTERED_HOTSPOT,
  CLEAR_FILTERED_HOTSPOT,
  CLEAR_FILTERED_FIELD_FLIGHTS_LIST,
  TOGGLE_FLIGHT_PRIORITY,
  TOGGLE_FLIGHT_PRIORITY_SUCCESS,
  TOGGLE_FLIGHT_PRIORITY_FAILURE,
  LOADING_CUSTOM_TAGS,
  LOADING_CUSTOM_TAGS_SUCCESS,
  LOADING_CUSTOM_TAGS_FAILURE,
  setCustomTag,
  fetchFlight,
  removeCustomTag,
} from './actions';
import { FilterTypes } from '../../constants';

const filterByListFields = (data, filters) => data.filter(rowEntry => filters.every((filterEntry) => {
  const { dataKey } = filterEntry;
  const value = filterEntry.cellDataGetter ? filterEntry.cellDataGetter({ rowData: rowEntry, dataKey }) : rowEntry[dataKey];
  return String(value).toLowerCase().startsWith(filterEntry.value.toLowerCase());
}));

const initial = {
  data: [],
  isFiltered: false,
  isFlightListFiltered: false,
  isBrushSelected: false,
  isHotspotSelected: false,
  filteredFlightList: undefined,
  brushSelection: undefined,
  hotspotFilter: undefined,
  filteredFlights: [],
  filtersActive: [],
  customTags: {},
};
export default (state = initial, action = {}) => {
  switch (action.type) {
    case LOADING_FLIGHTS_LIST_DATA: {
      return state;
    }
    case LOADING_FLIGHTS_LIST_DATA_FAILURE: {
      return { ...state, error: action.error };
    }
    case LOADING_FLIGHTS_LIST_DATA_SUCCESS: {
      const filteredFlights = FilterUtils.filterByKey('gufi', action.flightsListData, state.filteredFlightList, state.brushSelection, state.hotspotFilter);
      return {
        ...state,
        data: FlightListUtil.sortCrossByEntryTime(action.flightsListData),
        filteredFlights,
      };
    }
    case FILTERED_FLIGHTS_LIST: {
      const filteredFlightList = filterByListFields(state.data, action.filters);
      const filteredFlights = FilterUtils.filterByKey('gufi', state.data, filteredFlightList, state.brushSelection, state.hotspotFilter);
      const updatedFiltersActive = state.filtersActive.find(filter => filter.type === FilterTypes.LIST) ?
        // replace it by the received
        state.filtersActive.map(filter => (filter.type === FilterTypes.LIST ? { ...filter, filters: action.filters } : filter)) :
        // else adds to the filters
        state.filtersActive.concat({
          type: FilterTypes.LIST, filters: action.filters,
        });
      return {
        ...state,
        isFlightListFiltered: true,
        isFiltered: true,
        filteredFlightList,
        filtersActive: updatedFiltersActive,
        filteredFlights,
      };
    }
    case CLEAR_FILTERED_FLIGHTS_LIST: {
      const filteredFlights = FilterUtils.filterByKey('gufi', state.data, state.hotspotFilter, state.brushSelection);
      const filtersActive = state.filtersActive.filter(filter => filter.type !== FilterTypes.LIST);
      return {
        ...state,
        isFlightListFiltered: false,
        isFiltered: state.isBrushSelected || state.isHotspotSelected,
        filteredFlightList: undefined,
        filteredFlights,
        filtersActive,
      };
    }
    case CLEAR_FILTERED_FIELD_FLIGHTS_LIST: {
      let updatedFilteredFlights = [];
      const listFiltersActive = state.filtersActive.find(filter => filter.type === FilterTypes.LIST);
      if (listFiltersActive && listFiltersActive.filters.length > 1) {
        const updatedListFiltersActive = { ...listFiltersActive, filters: listFiltersActive.filters.filter(filter => filter.dataKey !== action.dataKey) };
        const updatedFiltersActive = state.filtersActive.map(filter => (filter.type === FilterTypes.LIST ? updatedListFiltersActive : filter));
        const updatedFilteredFlightList = filterByListFields(state.data, updatedListFiltersActive.filters);
        updatedFilteredFlights = FilterUtils.filterByKey('gufi', state.data, state.hotspotFilter, state.brushSelection, updatedFilteredFlightList);
        return {
          ...state,
          filtersActive: updatedFiltersActive,
          filteredFlightList: updatedFilteredFlightList,
          filteredFlights: updatedFilteredFlights,
        };
      }
      updatedFilteredFlights = FilterUtils.filterByKey('gufi', state.data, state.hotspotFilter, state.brushSelection);
      return {
        ...state,
        isFlightListFiltered: false,
        isFiltered: state.isBrushSelected || state.isHotspotSelected,
        filtersActive: state.filtersActive.filter(filter => filter.type !== FilterTypes.LIST),
        filteredFlightList: undefined,
        filteredFlights: updatedFilteredFlights,
      };
    }
    case BRUSHED_FLIGHTS: {
      const filteredFlights = FilterUtils.filterByKey('gufi', state.data, state.filteredFlightList, state.hotspotFilter, action.brushSelection);
      // if there is already a brush filter active
      const updatedFiltersActive = state.filtersActive.filter(filter => filter.type === FilterTypes.BRUSH).length > 0 ?
        // replace it by the received
        state.filtersActive.map(filter => (filter.type === FilterTypes.BRUSH ? {
          ...filter, tfvId: action.tfvId, from: action.from, to: action.to,
        } : filter)) :
        // else adds to the filters
        state.filtersActive.concat({
          type: FilterTypes.BRUSH, tfvId: action.tfvId, from: action.from, to: action.to,
        });
      return {
        ...state,
        isBrushSelected: true,
        isFiltered: true,
        brushSelection: action.brushSelection,
        filtersActive: updatedFiltersActive,
        filteredFlights,
      };
    }
    case CLEAR_BRUSH: {
      const filteredFlights = FilterUtils.filterByKey('gufi', state.data, state.filteredFlightList, state.hotspotFilter);
      const updatedFiltersActive = state.filtersActive.filter(filter => filter.type !== FilterTypes.BRUSH);
      return {
        ...state,
        isBrushSelected: false,
        isFiltered: state.isFlightListFiltered || state.isHotspotSelected,
        brushSelection: undefined,
        filtersActive: updatedFiltersActive,
        filteredFlights,
      };
    }
    case FILTERED_HOTSPOT: {
      const { hotspotFilter, hotspot } = action;
      const filteredFlights = FilterUtils.filterByKey('gufi', state.data, state.filteredFlightList, state.brushSelection, hotspotFilter);
      const updatedFiltersActive = state.filtersActive.filter(filter => filter.type === FilterTypes.HOTSPOT).length > 0 ?
        // replace it by the received
        state.filtersActive.map(filter => (filter.type === FilterTypes.HOTSPOT ? { ...filter, hotspot } : filter)) :
        // else adds to the filters
        state.filtersActive.concat({ type: FilterTypes.HOTSPOT, hotspot });
      return {
        ...state,
        isHotspotSelected: true,
        isFiltered: true,
        filtersActive: updatedFiltersActive,
        hotspotFilter,
        filteredFlights,
      };
    }
    case CLEAR_FILTERED_HOTSPOT: {
      const filteredFlights = FilterUtils.filterByKey('gufi', state.data, state.filteredFlightList, state.brushSelection);
      const updatedFiltersActive = state.filtersActive.filter(filter => filter.type !== FilterTypes.HOTSPOT);
      return {
        ...state,
        isHotspotSelected: false,
        isFiltered: state.isFlightListFiltered || state.isBrushSelected,
        hotspotFilter: undefined,
        filtersActive: updatedFiltersActive,
        filteredFlights,
      };
    }
    case TOGGLE_FLIGHT_PRIORITY: {
      return state;
    }
    case TOGGLE_FLIGHT_PRIORITY_SUCCESS: {
      return state;
    }
    case TOGGLE_FLIGHT_PRIORITY_FAILURE: {
      return { ...state, error: action.error };
    }
    case LOADING_CUSTOM_TAGS: {
      return state;
    }
    case LOADING_CUSTOM_TAGS_FAILURE: {
      return { ...state, error: action.error };
    }
    case LOADING_CUSTOM_TAGS_SUCCESS: {
      return {
        ...state,
        customTags: action.payload.tags,
      };
    }
    case setCustomTag.pending: {
      return state;
    }
    case setCustomTag.fulfilled: {
      return state;
    }
    case setCustomTag.rejected: {
      return { ...state, error: action.payload };
    }
    case fetchFlight.pending: {
      return state;
    }
    case fetchFlight.fulfilled: {
      const flightList = state.data;
      const index = flightList.findIndex(flight => flight.gufi === action.payload.gufi);
      flightList[index] = action.payload;
      return {
        ...state,
        data: flightList,
      };
    }
    case fetchFlight.rejected: {
      return { ...state, error: action.payload };
    }
    case removeCustomTag.pending: {
      return state;
    }
    case removeCustomTag.fulfilled: {
      return state;
    }
    case removeCustomTag.rejected: {
      return { ...state, error: action.payload };
    }
    default: {
      return state;
    }
  }
};
