import { Time } from '../../constants';
import {
  LOADING_USER_INFO,
  LOADING_USER_INFO_SUCCESS,
  LOADING_USER_INFO_FAILURE,
  LOADING_USER_FORECAST_PERIOD,
  LOADING_USER_FORECAST_PERIOD_SUCCESS,
  LOADING_USER_FORECAST_PERIOD_FAILURE,
} from './actions';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case LOADING_USER_INFO: {
      return state;
    }
    case LOADING_USER_INFO_SUCCESS: {
      return {
        ...state,
        uid: action.uid,
        area: action.area,
        tfv: action.tfv,
        acc: action.acc,
        secto: action.secto,
        neighbours: action.neighbours,
        entryStep: action.entryStep / Time.MINUTE_IN_MILISECONDS,
        occStep: action.occStep / Time.MINUTE_IN_MILISECONDS,
        entryDuration: action.entryDuration / Time.MINUTE_IN_MILISECONDS,
        occDuration: action.occDuration / Time.MINUTE_IN_MILISECONDS,
      };
    }
    case LOADING_USER_INFO_FAILURE: {
      return { ...state, error: action.error };
    }
    case LOADING_USER_FORECAST_PERIOD: {
      return state;
    }
    case LOADING_USER_FORECAST_PERIOD_SUCCESS: {
      return { ...state, forecastPeriod: action.forecastPeriod, forecastPeriodCharts: action.forecastPeriodCharts };
    }
    case LOADING_USER_FORECAST_PERIOD_FAILURE: {
      return { ...state, error: action.error };
    }
    default: {
      return state;
    }
  }
};
