import callApi from '../api/services';
import { EntryPaths } from '../../constants';

const queryEntryData = (tfvs, start, end, step, duration, sessionId, waitForJob, invalidate, horizon) => {
  let params = '';

  if (step) {
    params = `${params}&step=${step}`;
  }
  if (duration) {
    params = `${params}&duration=${duration}`;
  }
  if (sessionId) {
    params = `${params}&sessionId=${sessionId}`;
  }
  if (waitForJob) {
    params = `${params}&waitForJob=${waitForJob}`;
  }
  if (invalidate) {
    params = `${params}&invalidate=${invalidate}`;
  }
  if (horizon) {
    params = `${params}&horizon=${horizon}`;
  }

  return callApi(
    `${EntryPaths.ENTRY}?tfv=${tfvs}&start=${start}&end=${end}${params}`,
    { basePath: window.env.ENTRY_API_BASE_URL },
  );
};

export default queryEntryData;
