/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import fetchAvdStatus from './actions';

const initialState = {
  avd: null,
};

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    clearStatusData: (state) => {
      state.avd = null;
    },
  },
  extraReducers: {
    [fetchAvdStatus.fulfilled](state, { payload }) {
      state.avd = payload;
    },
    [fetchAvdStatus.rejected](state, {
      payload }) {
      state.error = payload;
    },
  },
});

export const { clearStatusData } = statusSlice.actions;

export default statusSlice.reducer;
