import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userAccessToken: undefined,
  channelId: undefined,
};

const cdmSlice = createSlice({
  name: 'cdm',
  initialState,
  reducers: {
    setCdmData(state, { payload }) {
      const { userAccessToken, channelId } = payload;
      return {
        ...state,
        userAccessToken,
        channelId,
      };
    },
  },
});

export const { setCdmData } = cdmSlice.actions;
export default cdmSlice.reducer;
