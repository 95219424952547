import {
  LOADING_OCCUPANCY_DATA,
  LOADING_OCCUPANCY_DATA_SUCCESS,
  LOADING_OCCUPANCY_DATA_FAILURE,
  LOADING_SECTO_PLAN_OCCUPANCY_DATA,
  LOADING_SECTO_PLAN_OCCUPANCY_DATA_SUCCESS,
  LOADING_SECTO_PLAN_OCCUPANCY_DATA_FAILURE,
} from './actions';

const initial = {
  data: [],
  dataHotspot: [],
  dataComparison: [],
  isLoading: false,
  isLoadingHotspot: false,
  isLoadingComparison: false,
  lastRequestData: {},
  lastRequestDataHotspot: {},
  lastRequestDataComparison: {},
  sectoPlanOccData: [],
};
export default (state = initial, action = {}) => {
  switch (action.type) {
    case LOADING_OCCUPANCY_DATA: {
      if (action.requestData.isHotspot) {
        return { ...state, isLoadingHotspot: true, lastRequestDataHotspot: action.requestData };
      }
      if (action.requestData.isComparison) {
        return { ...state, isLoadingComparison: true, lastRequestDataComparison: action.requestData };
      }
      return { ...state, isLoading: true, lastRequestData: action.requestData };
    }
    // Only stores data regarding the last request
    case LOADING_OCCUPANCY_DATA_SUCCESS: {
      if (action.requestData.isHotspot) {
        return state.lastRequestDataHotspot === action.requestData ?
          { ...state, dataHotspot: action.occupancyData, isLoadingHotspot: false } : { ...state, isLoadingHotspot: true };
      }
      if (action.requestData.isComparison) {
        return state.lastRequestDataComparison === action.requestData ?
          { ...state, dataComparison: action.occupancyData, isLoadingComparison: false } : { ...state, isLoadingComparison: true };
      }
      return state.lastRequestData === action.requestData ? { ...state, data: action.occupancyData, isLoading: false } : { ...state, isLoading: true };
    }
    case LOADING_OCCUPANCY_DATA_FAILURE: {
      if (action.requestData.isHotspot) {
        return { ...state, error: action.error, isLoadingHotspot: false };
      }
      if (action.requestData.isComparison) {
        return { ...state, error: action.error, isLoadingComparison: false };
      }
      return { ...state, error: action.error, isLoading: false };
    }
    case LOADING_SECTO_PLAN_OCCUPANCY_DATA: {
      return { ...state, isLoading: true, lastRequestData: action.requestData };
    }

    case LOADING_SECTO_PLAN_OCCUPANCY_DATA_SUCCESS: {
      return state.lastRequestData === action.requestData ?
        { ...state, sectoPlanOccData: action.sectoPlanOccupancyData, isLoading: false } : { ...state, isLoading: true };
    }
    case LOADING_SECTO_PLAN_OCCUPANCY_DATA_FAILURE: {
      return { ...state, error: action.error, isLoading: false };
    }
    default: {
      return state;
    }
  }
};
