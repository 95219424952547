import {
  TOGGLE_NO_FLIGHT_AREAS,
  TOGGLE_USER_DEFINED_AREAS,
  CHANGE_NO_FLIGHT_AREAS_FILTER,
  CHANGE_USER_DEFINED_AREAS_FILTER,
  CREATE_LOCAL_DEFINED_AREA,
  CHANGE_DEFINED_AREA_IN_EDIT,
  CLEAR_DEFINED_AREA_IN_EDIT,
  CHANGE_DEFINED_AREA_IN_DELETE,
  CLEAR_DEFINED_AREA_IN_DELETE,
  createDefinedArea,
  editDefinedArea,
  deleteDefinedArea,
  fetchNoFlightAreas,
  fetchUserDefinedAreas,
} from './actions';
import { DefinedAreasTypes, DefinedAreasDisplayStatus } from '../../constants';

const definedAreaToGeoJson = (definedArea) => {
  const { shape, ...properties } = definedArea;
  return { type: 'Feature', geometry: shape, properties };
};

const initial = {
  isNoFlightAreasVisible: true,
  isUserDefinedAreasVisible: true,
  noFlightAreas: { features: [] },
  userDefinedAreas: { features: [] },
  isEditing: false,
  isDeleting: false,
  elementInEdit: undefined,
  noFlightAreasFilter: DefinedAreasDisplayStatus.ACTIVE,
  userDefinedAreasFilter: DefinedAreasDisplayStatus.ACTIVE,
};

const defaultValues = {
  name: 'UD', flMax: 0, flMin: 0,
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case TOGGLE_NO_FLIGHT_AREAS: {
      return { ...state, isNoFlightAreasVisible: !state.isNoFlightAreasVisible };
    }
    case TOGGLE_USER_DEFINED_AREAS: {
      return { ...state, isUserDefinedAreasVisible: !state.isUserDefinedAreasVisible };
    }
    case CHANGE_NO_FLIGHT_AREAS_FILTER: {
      return { ...state, noFlightAreasFilter: action.filter };
    }
    case CHANGE_USER_DEFINED_AREAS_FILTER: {
      return { ...state, userDefinedAreasFilter: action.filter };
    }

    case fetchNoFlightAreas.pending.type:
    case fetchNoFlightAreas.rejected.type: {
      return state;
    }

    case fetchNoFlightAreas.fulfilled.type: {
      const definedAreasData = action.payload;

      if (state.elementInEdit) {
        const newNoFlightAreaFeatures = definedAreasData.features.filter(feature =>
          ((feature.properties.id !== state.elementInEdit.properties.id) && (feature.properties.areaType !== DefinedAreasTypes.UDA)));
        return { ...state, noFlightAreas: { ...definedAreasData, features: newNoFlightAreaFeatures } };
      }

      const noFlightAreaFeatures = definedAreasData.features.filter(feature => feature.properties.areaType !== DefinedAreasTypes.UDA);
      noFlightAreaFeatures.map(feature => feature.properties.activations.sort((a, b) => (a.startTime > b.startTime ? 1 : -1)));

      return { ...state, noFlightAreas: { ...definedAreasData, features: noFlightAreaFeatures } };
    }

    case fetchUserDefinedAreas.pending.type:
    case fetchUserDefinedAreas.rejected.type: {
      return state;
    }

    case fetchUserDefinedAreas.fulfilled.type: {
      const definedAreasData = action.payload;

      if (state.elementInEdit) {
        const newUserDefinedAreasFeatures = definedAreasData.features.filter(feature =>
          ((feature.properties.id !== state.elementInEdit.properties.id) && (feature.properties.areaType === DefinedAreasTypes.UDA)));
        return {
          ...state, userDefinedAreas: { ...definedAreasData, features: newUserDefinedAreasFeatures },
        };
      }

      const userDefinedAreasFeatures = definedAreasData.features.filter(feature => feature.properties.areaType === DefinedAreasTypes.UDA);

      return { ...state, userDefinedAreas: { ...definedAreasData, features: userDefinedAreasFeatures } };
    }

    case CREATE_LOCAL_DEFINED_AREA: {
      let newFeature;
      if (action.geoJson) {
        newFeature = { ...action.geoJson, properties: { ...defaultValues, ...action.geoJson.properties } };
      } else {
        newFeature = { properties: { ...defaultValues, type: 'Circle' } };
      }
      return { ...state, isEditing: true, elementInEdit: newFeature };
    }
    case CHANGE_DEFINED_AREA_IN_EDIT: {
      let userDefinedAreasFeatures = [...state.userDefinedAreas.features];
      // If there is an already submitted area in edit we re-add that to the areas not in edit
      if (state.elementInEdit && state.elementInEdit.properties.id) {
        userDefinedAreasFeatures = userDefinedAreasFeatures.concat(state.elementInEdit);
      }
      const newUserDefinedAreasFeatures = userDefinedAreasFeatures.filter(feature => feature.properties.id !== action.definedArea.properties.id);
      return {
        ...state, userDefinedAreas: { ...state.userDefinedAreas, features: newUserDefinedAreasFeatures }, isEditing: true, elementInEdit: action.definedArea,
      };
    }
    case CLEAR_DEFINED_AREA_IN_EDIT: {
      if (state.elementInEdit && state.elementInEdit.properties && state.elementInEdit.properties.id) {
        const newUserDefinedAreasFeatures = state.userDefinedAreas.features.concat(state.elementInEdit);
        return {
          ...state, userDefinedAreas: { ...state.userDefinedAreas, features: newUserDefinedAreasFeatures }, isEditing: false, elementInEdit: undefined, isDeleting: false,
        };
      }
      return {
        ...state, isEditing: false, elementInEdit: undefined, isDeleting: false,
      };
    }
    case CHANGE_DEFINED_AREA_IN_DELETE: {
      return { ...state, isDeleting: true, elementInEdit: action.definedArea };
    }
    case CLEAR_DEFINED_AREA_IN_DELETE: {
      return { ...state, isDeleting: false, elementInEdit: state.isEditing ? state.elementInEdit : undefined };
    }

    case editDefinedArea.pending.type:
    case createDefinedArea.pending.type:
    case deleteDefinedArea.pending.type:
      return state;

    case editDefinedArea.fulfilled.type:
    case createDefinedArea.fulfilled.type: {
      const translatedDefinedArea = definedAreaToGeoJson(action.payload);
      const newUserDefinedAreasFeatures = state.userDefinedAreas.features.concat(translatedDefinedArea);
      return {
        ...state, error: undefined, userDefinedAreas: { ...state.userDefinedAreas, features: newUserDefinedAreasFeatures }, isEditing: false, elementInEdit: undefined,
      };
    }

    case editDefinedArea.rejected.type:
    case createDefinedArea.rejected.type:
    case deleteDefinedArea.rejected.type: {
      const rawError = action.error;
      const error = JSON.parse(rawError.message);
      return { ...state, error };
    }

    case deleteDefinedArea.fulfilled.type:
      return {
        ...state, isEditing: false, isDeleting: false, elementInEdit: undefined,
      };

    default: {
      return state;
    }
  }
};
