/* eslint-disable no-param-reassign */
import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isPretactSessionActive: false,
  pretactSettings: {},
  currTimestamp: undefined,
};

const pretactSlice = createSlice({
  name: 'pretact',
  initialState,
  reducers: {
    startPretactSession(state, { payload }) {
      const { date, time } = payload;
      state.isPretactSessionActive = true;
      state.pretactSettings = { date, time };
      state.currTimestamp = moment(date).startOf('day').set({ hour: moment(time).get('hour'), minute: moment(time).get('minute') }).valueOf();
    },
    endPretactSession(state) {
      state.isPretactSessionActive = false;
    },
    updatePretactSession(state, { payload }) {
      state.currTimestamp = payload.timestamp;
    },
  },
});

export const { startPretactSession, endPretactSession, updatePretactSession } = pretactSlice.actions;
export default pretactSlice.reducer;
