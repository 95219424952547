import { v4 as uuidv4 } from 'uuid';

import { updateEntryData, updateOccupancyData } from '../../utils/Common';
import {
  CLEAR_ENTRY_ERRORS_PUT,
  CLEAR_OCC_ERRORS_PUT,
  LOADING_CAPA_PLANS_DATA,
  LOADING_CAPA_PLANS_DATA_FAILURE,
  LOADING_CAPA_PLANS_DATA_OCC_FAILURE,
  LOADING_CAPA_PLANS_DATA_OCC_SUCCESS,
  LOADING_CAPA_PLANS_DATA_SUCCESS,
  LOADING_CAPA_PLANS_ENTRY_DATA,
  LOADING_CAPA_PLANS_ENTRY_DATA_FAILURE,
  LOADING_CAPA_PLANS_ENTRY_DATA_SUCCESS,
  LOADING_CAPA_PLANS_OCC_DATA,
  PUT_CAPA_PLANS_ENTRY_DATA,
  PUT_CAPA_PLANS_ENTRY_DATA_FAILURE,
  PUT_CAPA_PLANS_ENTRY_DATA_SUCCESS,
  PUT_CAPA_PLANS_OCC_DATA,
  PUT_CAPA_PLANS_OCC_DATA_FAILURE,
  PUT_CAPA_PLANS_OCC_DATA_SUCCESS,
} from './actions';

const initial = {
  isLoading: false,
  isLoadingHotspot: false,
  isLoadingComparison: false,
  isLoadingSectoPlanning: false,
  lastRequestData: {},
  lastRequestDataHotspot: {},
  lastRequestDataComparison: {},
  lastRequestSectoPlanning: {},
  data: {},
  dataHotspot: {},
  dataComparison: {},
  dataSectoPlanning: {},
  dataOcc: { isLoading: false },
  dataEntry: { isLoading: false },
  errorOccPut: [],
  errorEntryPut: [],
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case LOADING_CAPA_PLANS_DATA: {
      if (action.requestData.isHotspot) {
        return { ...state, isLoadingHotspot: true, lastRequestDataHotspot: action.requestData };
      }
      if (action.requestData.isComparison) {
        return { ...state, isLoadingComparison: true, lastRequestDataComparison: action.requestData };
      }
      if (action.requestData.isSectoPlanning) {
        return { ...state, isLoadingSectoPlanning: true, lastRequestSectoPlanning: action.requestData };
      }
      return { ...state, isLoading: true, lastRequestData: action.requestData };
    }
    case LOADING_CAPA_PLANS_DATA_SUCCESS: {
      if (action.requestData.isHotspot) {
        return state.lastRequestDataHotspot === action.requestData ?
          { ...state, dataHotspot: action.capaPlansData, isLoadingHotspot: false } : { ...state, isLoadingHotspot: true };
      }
      if (action.requestData.isComparison) {
        return state.lastRequestDataComparison === action.requestData ?
          { ...state, dataComparison: action.capaPlansData, isLoadingComparison: false } : { ...state, isLoadingComparison: true };
      }
      if (action.requestData.isSectoPlanning) {
        return state.lastRequestSectoPlanning === action.requestData ?
          { ...state, dataSectoPlanning: action.capaPlansData, isLoadingSectoPlanning: false } : { ...state, isLoadingSectoPlanning: true };
      }
      return { ...state, data: { ...state.data, ...action.capaPlansData }, isLoading: false };
    }
    case LOADING_CAPA_PLANS_DATA_FAILURE: {
      if (action.requestData.isHotspot) {
        return { ...state, error: action.error, isLoadingHotspot: false };
      }
      if (action.requestData.isComparison) {
        return { ...state, error: action.error, isLoadingComparison: false };
      }
      if (action.requestData.isSectoPlanning) {
        return { ...state, error: action.error, isLoadingSectoPlanning: false };
      }
      return { ...state, error: action.error, isLoading: false, data: {} };
    }
    case LOADING_CAPA_PLANS_OCC_DATA: {
      return { ...state, dataOcc: { isLoading: true } };
    }
    case LOADING_CAPA_PLANS_DATA_OCC_SUCCESS: {
      const occupancyWithKey = action.capaPlansOccData.occupancy.map(dayPlan => (
        { ...dayPlan,
          occPlan: dayPlan.occPlan.map((plan) => {
            const key = uuidv4();
            return { ...plan, key };
          }) }
      ));

      return { ...state, dataOcc: { isLoading: false, name: action.capaPlansOccData.name, occupancy: occupancyWithKey } };
    }
    case LOADING_CAPA_PLANS_DATA_OCC_FAILURE: {
      return { ...state, dataOcc: { ...state.dataOcc, isLoading: false, error: action.error } };
    }
    case PUT_CAPA_PLANS_OCC_DATA: {
      return { ...state, dataOcc: { ...state.dataOcc, isLoading: true } };
    }
    case PUT_CAPA_PLANS_OCC_DATA_SUCCESS: {
      const { data } = action;

      const newOccData = updateOccupancyData(state.data.occupancy, data);

      if (Object.keys(state.dataSectoPlanning).length > 0) {
        const newSectoPlanOcc = updateOccupancyData(state.dataSectoPlanning.occupancy, data);

        return { ...state,
          dataOcc: { ...state.dataOcc, isLoading: false },
          data: { ...state.data, occupancy: newOccData },
          dataSectoPlanning: { ...state.dataSectoPlanning, occupancy: newSectoPlanOcc },
        };
      }

      return { ...state,
        dataOcc: { ...state.dataOcc, isLoading: false },
        data: { ...state.data, occupancy: newOccData },
      };
    }
    case PUT_CAPA_PLANS_OCC_DATA_FAILURE: {
      const errorToAdd = { tfv: action.tfv, date: action.date, ...action.err };
      return { ...state, errorOccPut: [...state.errorOccPut, errorToAdd], dataOcc: { ...state.dataOcc, isLoading: false } };
    }
    case CLEAR_OCC_ERRORS_PUT: {
      return { ...state, errorOccPut: [] };
    }
    case LOADING_CAPA_PLANS_ENTRY_DATA: {
      return { ...state, dataEntry: { isLoading: true } };
    }
    case LOADING_CAPA_PLANS_ENTRY_DATA_SUCCESS: {
      const entryWithKey = action.capaPlansEntryData.entry.map(dayPlan => (
        { ...dayPlan,
          entryPlan: dayPlan.entryPlan.map((plan) => {
            const key = uuidv4();
            return { ...plan, key };
          }) }
      ));

      return { ...state, dataEntry: { isLoading: false, name: action.capaPlansEntryData.name, entry: entryWithKey } };
    }
    case LOADING_CAPA_PLANS_ENTRY_DATA_FAILURE: {
      return { ...state, dataEntry: { ...state.dataEntry, isLoading: false, error: action.error } };
    }
    case PUT_CAPA_PLANS_ENTRY_DATA: {
      return { ...state, dataEntry: { ...state.dataEntry, isLoading: true } };
    }
    case PUT_CAPA_PLANS_ENTRY_DATA_SUCCESS: {
      const { data } = action;

      const newEntryData = updateEntryData(state.data.entry, data.plan.entryPlan[0]);

      if (Object.keys(state.dataSectoPlanning).length > 0) {
        const newSectoPlanEntry = updateEntryData(state.dataSectoPlanning.entry, data.plan.entryPlan[0]);

        return { ...state,
          dataEntry: { ...state.dataEntry, isLoading: false },
          data: { ...state.data, entry: newEntryData },
          dataSectoPlanning: { ...state.dataSectoPlanning, entry: newSectoPlanEntry },
        };
      }
      return { ...state,
        dataEntry: { ...state.dataEntry, isLoading: false },
        data: { ...state.data, entry: newEntryData },
      };
    }
    case PUT_CAPA_PLANS_ENTRY_DATA_FAILURE: {
      const errorToAdd = { tfv: action.tfv, date: action.date, ...action.err };
      return { ...state, errorEntryPut: [...state.errorEntryPut, errorToAdd], dataEntry: { ...state.dataEntry, isLoading: false } };
    }
    case CLEAR_ENTRY_ERRORS_PUT: {
      return { ...state, errorEntryPut: [] };
    }
    default: {
      return state;
    }
  }
};
