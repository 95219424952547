import {
  LOADING_CAT_DATA,
  LOADING_CAT_DATA_SUCCESS,
  LOADING_CAT_DATA_FAILURE,
} from './actions';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case LOADING_CAT_DATA: {
      return state;
    }
    case LOADING_CAT_DATA_SUCCESS: {
      return { ...state, catData: action.catData };
    }
    case LOADING_CAT_DATA_FAILURE: {
      return { ...state, error: action.error };
    }
    default: {
      return state;
    }
  }
};
