export const getPath = (href, pathsToSkip = []) => {
  const substringToIndexesOf = (fullUrl, charsToSubstringTo) => charsToSubstringTo.reduce(
    (prev, char) => {
      if (prev.includes(char)) {
        return prev.substring(0, prev.indexOf(char));
      }
      return prev;
    },
    fullUrl,
  );

  const url = substringToIndexesOf(href, ['#', '?']);

  const parts = url.split('/');

  let lastPart = parts.length - 1;
  let path = parts[lastPart];

  while (path.trim() === '' || pathsToSkip.includes(`/${path}`)) {
    lastPart -= 1;
    path = parts[lastPart];
  }
  if (lastPart <= 2) {
    // we've reached the host part, i.e., path is empty
    return '/';
  }
  const result = parts.slice(3, lastPart + 1);

  return `/${result.join('/')}`;
};

export const getHash = (href) => {
  if (!href.includes('#')) {
    return '';
  }

  return href.split('#')[1];
};
