import { queryKpisData } from './services';

export const LOADING_KPIS_DATA = 'LOADING_KPIS_DATA';
export function loadingKpisData() {
  return {
    type: LOADING_KPIS_DATA,
  };
}

export const LOADING_KPIS_DATA_SUCCESS = 'LOADING_KPIS_DATA_SUCCESS';
export function loadingKpisDataSuccess(data) {
  return {
    type: LOADING_KPIS_DATA_SUCCESS,
    data,
  };
}

export const LOADING_KPIS_DATA_FAILURE = 'LOADING_KPIS_DATA_FAILURE';
export function loadingKpisDataFailure(error) {
  return {
    type: LOADING_KPIS_DATA_FAILURE,
    error,
  };
}

/* Async Action Creators */
export const fetchKpisData = (firName, currentTime, horizon) => (dispatch) => {
  dispatch(loadingKpisData());
  return queryKpisData(firName, currentTime, horizon)
    .then((response) => {
      dispatch(loadingKpisDataSuccess(response));
    }).catch((err) => {
      dispatch(loadingKpisDataFailure(err));
    });
};
