import { queryNotamData } from './services';

export const LOADING_NOTAM_DATA = 'LOADING_NOTAM_DATA';
export function loadingNotamData() {
  return {
    type: LOADING_NOTAM_DATA,
  };
}

export const LOADING_NOTAM_DATA_SUCCESS = 'LOADING_NOTAM_DATA_SUCCESS';
export function loadingNotamDataSuccess(data) {
  return {
    type: LOADING_NOTAM_DATA_SUCCESS,
    data,
  };
}

export const LOADING_NOTAM_DATA_FAILURE = 'LOADING_NOTAM_DATA_FAILURE';
export function loadingNotamDataFailure(error) {
  return {
    type: LOADING_NOTAM_DATA_FAILURE,
    error,
  };
}

/* Async Action Creators */
export const fetchNotamData = () => (dispatch) => {
  dispatch(loadingNotamData());
  return queryNotamData()
    .then((response) => {
      dispatch(loadingNotamDataSuccess(response));
    }).catch((err) => {
      dispatch(loadingNotamDataFailure(err));
    });
};
