import { queryTimeData, queryCurrentVersion } from './services';

export const LOADING_TIME_DATA = 'LOADING_TIME_DATA';
export function loadingTimeData() {
  return {
    type: LOADING_TIME_DATA,
  };
}

export const LOADING_TIME_DATA_SUCCESS = 'LOADING_TIME_DATA_SUCCESS';
export function loadingTimeDataSuccess(timeData) {
  return {
    type: LOADING_TIME_DATA_SUCCESS,
    timeData,
  };
}

export const LOADING_TIME_DATA_FAILURE = 'LOADING_TIME_DATA_FAILURE';
export function loadingTimeDataFailure(error) {
  return {
    type: LOADING_TIME_DATA_FAILURE,
    error,
  };
}

export const UPDATE_VERSION = 'UPDATE_VERSION';
export function updateCurrentVersion(data) {
  return {
    type: UPDATE_VERSION,
    data,
  };
}

export const REPLAY_SESSION_RUN = 'REPLAY_SESSION_RUN';
export function runReplaySession({ time, startedAt }) {
  return {
    type: REPLAY_SESSION_RUN,
    payload: {
      time,
      startedAt,
    },
  };
}

export const REPLAY_SESSION_PAUSE = 'REPLAY_SESSION_PAUSE';
export function pauseReplaySession() {
  return {
    type: REPLAY_SESSION_PAUSE,
  };
}

export const REPLAY_SESSION_STOP = 'REPLAY_SESSION_STOP';
export function stopReplaySession({ time, startedAt }) {
  return {
    type: REPLAY_SESSION_STOP,
    payload: {
      time,
      startedAt,
    },
  };
}

/* Async Action Creators */
export const fetchTimeData = () => (dispatch) => {
  dispatch(loadingTimeData());
  return queryTimeData()
    .then(data => dispatch(loadingTimeDataSuccess(data)))
    .catch(error => dispatch(loadingTimeDataFailure(error)));
};

export const fetchCurrentVersion = () => dispatch =>
  queryCurrentVersion()
    .then((response) => {
      dispatch(updateCurrentVersion(response));
    });
