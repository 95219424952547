import moment from 'moment';
import { TimeTypes } from '../../constants';

export const selectTime = state => state.time;

export const selectReplayTime = (state) => {
  const { replay } = selectTime(state);
  return replay || {};
};

export const selectTimeData = (state) => {
  if (selectTime(state).currTime) {
    if (selectTime(state).currTime.status.toUpperCase() === TimeTypes.FIXED) {
      return selectTime(state).currTime.time;
    }
    return moment().valueOf() + selectTime(state).currTime.deltaTime;
  }
  return undefined;
};

export const selectTimeRounded = (state) => {
  const timeData = selectTimeData(state);
  if (timeData) {
    return moment(timeData).second(0).milliseconds(0).valueOf();
  }
  return undefined;
};

export const selectCurrentTime = state => selectTime(state).currTime;

export const selectCurrentVersion = state => selectTime(state).version;
