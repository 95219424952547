import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import LoginPage from './containers/LoginPage/LoginPage';
import App from './containers/App/App';
import { AlertProvider } from './containers/Context/AlertContext';
import { AppProvider } from './containers/Context/AppContext';
import { AuthProvider } from './containers/Context/AuthContext';
import { GlobalProvider } from './containers/Context/GlobalContext';
import { TmiProvider } from './containers/Context/TmiContext';
import { TrafficLoadProvider } from './containers/Context/TrafficLoadContext';
import { UserProvider } from './containers/Context/UserContext';
import { MapProvider } from './containers/Context/MapContext';
import { TooltipProvider } from './containers/Context/TooltipContext';
import Loader from './components/Loader/Loader';

const ReportDocument = lazy(() => import('./components/ReportDocument/ReportDocument'));
const ThirdPartyDocument = lazy(() => import('./components/ThirdPartyDocument/ThirdPartyDocument'));
const PostOpsHome = lazy(() => import('./containers/Home/PostOpsHome'));
const PretactHome = lazy(() => import('./containers/Home/PretactHome'));
const TacticalHome = lazy(() => import('./containers/Home/TacticalHome'));

const routes = () => (
  <App>
    <Suspense fallback={<Loader />}>
      <AuthProvider>
        <AppProvider>
          <AlertProvider>
            <MapProvider>
              <TooltipProvider>
                <UserProvider>
                  <TmiProvider>
                    <TrafficLoadProvider>
                      <GlobalProvider>
                        <Switch>
                          <Route path="/login" component={LoginPage} />
                          <Route path="/oauth/login" component={LoginPage} />
                          <Route path="/:path/login" component={LoginPage} />
                          <Route path="/:path/oauth/login" component={LoginPage} />
                          <PrivateRoute path="/reports/:fir/:from/:to/:occStep/:entryStep" component={ReportDocument} />
                          <PrivateRoute path="/thirdPartyNotices" component={ThirdPartyDocument} />
                          <PrivateRoute path="/postops" component={PostOpsHome} />
                          <PrivateRoute path="/pretact" component={PretactHome} />
                          <PrivateRoute path="*" component={TacticalHome} />
                        </Switch>
                      </GlobalProvider>
                    </TrafficLoadProvider>
                  </TmiProvider>
                </UserProvider>
              </TooltipProvider>
            </MapProvider>
          </AlertProvider>
        </AppProvider>
      </AuthProvider>
    </Suspense>
  </App>
);

export default routes;
