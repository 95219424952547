import queryOccupancyData from './services';
import { Time } from '../../constants';

export const LOADING_OCCUPANCY_DATA = 'LOADING_OCCUPANCY_DATA';
export function loadingOccupancyData(requestData) {
  return {
    type: LOADING_OCCUPANCY_DATA,
    requestData,
  };
}

export const LOADING_SECTO_PLAN_OCCUPANCY_DATA = 'LOADING_SECTO_PLAN_OCCUPANCY_DATA';
export function loadingSectoPlanOccupancyData(requestData) {
  return {
    type: LOADING_SECTO_PLAN_OCCUPANCY_DATA,
    requestData,
  };
}

export const LOADING_OCCUPANCY_DATA_SUCCESS = 'LOADING_OCCUPANCY_DATA_SUCCESS';
export function loadingOccupancyDataSuccess(occupancyData, requestData) {
  return {
    type: LOADING_OCCUPANCY_DATA_SUCCESS,
    occupancyData,
    requestData,
  };
}

export const LOADING_SECTO_PLAN_OCCUPANCY_DATA_SUCCESS = 'LOADING_SECTO_PLAN_OCCUPANCY_DATA_SUCCESS';
export function loadingSectoPlanOccupancyDataSuccess(sectoPlanOccupancyData, requestData) {
  return {
    type: LOADING_SECTO_PLAN_OCCUPANCY_DATA_SUCCESS,
    sectoPlanOccupancyData,
    requestData,
  };
}

export const LOADING_OCCUPANCY_DATA_FAILURE = 'LOADING_OCCUPANCY_DATA_FAILURE';
export function loadingOccupancyDataFailure(error) {
  return {
    type: LOADING_OCCUPANCY_DATA_FAILURE,
    error,
  };
}

export const LOADING_SECTO_PLAN_OCCUPANCY_DATA_FAILURE = 'LOADING_SECTO_PLAN_OCCUPANCY_DATA_FAILURE';
export function loadingSectoPlanOccupancyDataFailure(error) {
  return {
    type: LOADING_SECTO_PLAN_OCCUPANCY_DATA_FAILURE,
    error,
  };
}

/* Async Action Creators */
export const fetchOccupancyData = (tfvs, start, end, step, duration, sessionId, waitForJob, invalidate, horizon, isComparison, isHotspot) => (dispatch) => {
  const miliStep = step * Time.MINUTE_IN_MILISECONDS;
  const miliDuration = duration * Time.MINUTE_IN_MILISECONDS;
  const requestData = {
    tfvs, start, end, miliStep, miliDuration, sessionId, isComparison, isHotspot,
  };
  dispatch(loadingOccupancyData(requestData));

  return queryOccupancyData(tfvs, start, end, miliStep, miliDuration, sessionId, waitForJob, invalidate, horizon)
    .then((occupancy) => {
      dispatch(loadingOccupancyDataSuccess(occupancy, requestData));
    }).catch((err) => {
      dispatch(loadingOccupancyDataFailure(err));
    });
};

export const fetchSectoPlanOccupancyData = (tfvs, start, end, step, duration, sessionId, waitForJob, invalidate, horizon) => (dispatch) => {
  const miliStep = step * Time.MINUTE_IN_MILISECONDS;
  const miliDuration = duration * Time.MINUTE_IN_MILISECONDS;
  const requestData = {
    tfvs, start, end, miliStep, miliDuration, sessionId,
  };
  dispatch(loadingSectoPlanOccupancyData(requestData));

  return queryOccupancyData(tfvs, start, end, miliStep, miliDuration, sessionId, waitForJob, invalidate, horizon)
    .then((sectoOccupancy) => {
      dispatch(loadingSectoPlanOccupancyDataSuccess(sectoOccupancy, requestData));
    }).catch((err) => {
      dispatch(loadingSectoPlanOccupancyDataFailure(err));
    });
};
