import { queryFlowData, createFlowData, putFlowData, deleteFlowData } from './services';
import { RegularExpressions } from '../../constants';

export const LOADING_FLOW_DATA = 'LOADING_FLOW_DATA';
export function loadingFlowData() {
  return {
    type: LOADING_FLOW_DATA,
  };
}

export const LOADING_FLOW_DATA_SUCCESS = 'LOADING_FLOW_DATA_SUCCESS';
export function loadingFlowDataSuccess(flow) {
  return {
    type: LOADING_FLOW_DATA_SUCCESS,
    flow,
  };
}

export const LOADING_FLOW_DATA_FAILURE = 'LOADING_FLOW_DATA_FAILURE';
export function loadingFlowDataFailure(error) {
  return {
    type: LOADING_FLOW_DATA_FAILURE,
    error,
  };
}

export const fetchFlowData = firName => (dispatch) => {
  dispatch(loadingFlowData());
  return queryFlowData(firName)
    .then(response => dispatch(loadingFlowDataSuccess(response)))
    .catch(error => dispatch(loadingFlowDataFailure(error)));
};

export const CREATING_FLOW_DATA = 'CREATING_FLOW_DATA';
export function creatingFlowData() {
  return {
    type: CREATING_FLOW_DATA,
    isLoading: true,
  };
}

export const CREATING_FLOW_DATA_SUCCESS = 'CREATING_FLOW_DATA_SUCCESS';
export function creatingFlowDataSuccess(flow) {
  return {
    type: CREATING_FLOW_DATA_SUCCESS,
    flow,
    isLoading: false,
  };
}

export const CREATING_FLOW_DATA_FAILURE = 'CREATING_FLOW_DATA_FAILURE';
export function creatingFlowDataFailure(error) {
  return {
    type: CREATING_FLOW_DATA_FAILURE,
    error,
    isLoading: false,
  };
}

export const postFlowData = (firName, flow) => (dispatch) => {
  dispatch(creatingFlowData());
  return createFlowData(firName, flow)
    .then((response) => {
      const location = response.headers.get('location');
      const id = parseInt(location.match(RegularExpressions.STRING_LAST_DIGITS), 10);
      return dispatch(creatingFlowDataSuccess({ ...flow, id }));
    })
    .catch(error => dispatch(creatingFlowDataFailure(error)));
};

export const UPDATING_FLOW_DATA = 'UPDATING_FLOW_DATA';
export function updatingFlowData() {
  return {
    type: UPDATING_FLOW_DATA,
    isLoading: true,
  };
}

export const UPDATING_FLOW_DATA_SUCCESS = 'UPDATING_FLOW_DATA_SUCCESS';
export function updatingFlowDataSuccess(flow) {
  return {
    type: UPDATING_FLOW_DATA_SUCCESS,
    isLoading: false,
    flow,
  };
}

export const UPDATING_FLOW_DATA_FAILURE = 'UPDATING_FLOW_DATA_FAILURE';
export function updatingFlowDataFailure(error) {
  return {
    type: UPDATING_FLOW_DATA_FAILURE,
    isLoading: false,
    error,
  };
}

export const updateFlowData = (id, firName, flow) => (dispatch) => {
  dispatch(updatingFlowData());
  return putFlowData(id, firName, flow)
    .then(() => dispatch(updatingFlowDataSuccess(flow)))
    .catch(error => dispatch(updatingFlowDataFailure(error)));
};

export const DELETE_FLOW_DATA = 'DELETE_FLOW_DATA';
export function removeFlowData() {
  return {
    type: DELETE_FLOW_DATA,
    isLoading: true,
  };
}

export const DELETE_FLOW_DATA_SUCCESS = 'DELETE_FLOW_DATA_SUCCESS';
export function removeFlowDataSuccess(id) {
  return {
    type: DELETE_FLOW_DATA_SUCCESS,
    isLoading: false,
    id,
  };
}

export const DELETE_FLOW_DATA_FAILURE = 'DELETE_FLOW_DATA_FAILURE';
export function removeFlowDataFailure(error) {
  return {
    type: DELETE_FLOW_DATA_FAILURE,
    isLoading: false,
    error,
  };
}

export const fetchRemoveFlowData = (id, firName) => (dispatch) => {
  dispatch(removeFlowData());
  return deleteFlowData(id, firName)
    .then(() => dispatch(removeFlowDataSuccess(id)))
    .catch(error => dispatch(removeFlowDataFailure(error)));
};
