import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteFavoriteTfv, putFavoriteTfv, queryFavoriteTfvs, queryTfvData } from './services';

export const fetchAccData = createAsyncThunk(
  'FETCH_ACC_DATA',
  async accName => queryTfvData(accName),
);

export const fetchSectorContour = createAsyncThunk(
  'FETCH_SECTOR_CONTOUR',
  async tfvName => queryTfvData(tfvName),
);

export const fetchHotspotContour = createAsyncThunk(
  'FETCH_HOTSPOT_CONTOUR',
  async hotspotName => queryTfvData(hotspotName),
);

export const fetchFavoriteTfvs = createAsyncThunk(
  'FETCH_FAVORITE_TFVS',
  async () => queryFavoriteTfvs(),
);

export const addFavoriteTfv = createAsyncThunk(
  'ADD_FAVORITE_TFV',
  async (tfvId) => {
    await putFavoriteTfv(tfvId);
    return tfvId;
  },
);

export const removeFavoriteTfv = createAsyncThunk(
  'REMOVE_FAVORITE_TFV',
  async (tfvId) => {
    await deleteFavoriteTfv(tfvId);
    return tfvId;
  },
);
