/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchFeatureFlags } from './actions';

const initialState = { };

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: { },
  extraReducers: {
    [fetchFeatureFlags.pending]: (state) => {
      state.isRequestingFeatureFlags = true;
    },
    [fetchFeatureFlags.fulfilled]: (state, { meta, payload }) => ({
      ...state,
      isRequestingFeatureFlags: false,
      [meta.arg]: payload.feature_flag,
    }),
    [fetchFeatureFlags.rejected]: (state, { payload }) => {
      state.isRequestingFeatureFlags = false;
      state.error = payload;
    },
  },
});

export default featureFlagsSlice.reducer;
