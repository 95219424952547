import callApi from '../api/services';
import { XuningPaths } from '../../constants';

export const getUserSettings = () => callApi(
  `${XuningPaths.SETTINGS}`,
  { basePath: window.env.XUNING_API_BASE_URL },
);

export const updateUserSettings = settings => callApi(
  `${XuningPaths.SETTINGS}`,
  { basePath: window.env.XUNING_API_BASE_URL, method: 'PATCH', body: settings },
);
