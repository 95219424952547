import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { FabCeTimes, LateralReroutingModeStatus, RegularExpressions, Severities, WeatherSeverities } from '../../constants';
import {
  CLEAR_FAB_CE_SECTOR,
  FLIGHT_LIST_ROW_CLICKED,
  FLIGHT_LIST_ROW_EXPANDED,
  LOADING_FAB_CE_DATA,
  LOADING_FAB_CE_DATA_FAILURE,
  LOADING_FAB_CE_DATA_SUCCESS,
  LOADING_FAB_CE_SECTOR,
  LOADING_FAB_CE_SECTOR_FAILURE,
  LOADING_FAB_CE_SECTOR_SUCCESS,
  LOADING_ROUTE_DATA,
  LOADING_ROUTE_DATA_FAILURE,
  LOADING_ROUTE_DATA_SUCCESS,
  LOADING_TRAJECTORY_AIRPORTS_DATA,
  LOADING_TRAJECTORY_AIRPORTS_DATA_FAILURE,
  LOADING_TRAJECTORY_AIRPORTS_DATA_SUCCESS,
  MARKER_CLICKED,
  SET_VISIBLE_CAT_WEATHER_SEVERITY_LEVEL,
  SET_VISIBLE_RDT_WEATHER_SEVERITY_LEVEL,
  SET_VISIBLE_TS_WEATHER_SEVERITY_LEVEL,
  SET_VISIBLE_TURB_WEATHER_SEVERITY_LEVEL,
  TOGGLE_CAT_WEATHER,
  TOGGLE_TS_WEATHER,
  TOGGLE_TURB_WEATHER,
  TOGGLE_FAB_CE,
  TOGGLE_FLOW,
  TOGGLE_LATERAL_REROUTING_MODE,
  TOGGLE_NFA,
  TOGGLE_RDT_WEATHER,
  TOGGLE_ROUTE,
  UPDATE_LATERAL_REROUTING_DATA,
} from './actions';

const calculateFabCeTimestamps = (fabCeData) => {
  const baseTime = moment.utc(fabCeData.time);
  const validTimeForHour = moment.utc(fabCeData.valid, 'HHZ');
  baseTime.hour(validTimeForHour.get('hour'));
  baseTime.minute(0);
  const baseTimestamp = baseTime.valueOf();
  let i = 0;
  const times = [];
  while (i < FabCeTimes.INTERVAL_NUMBER) {
    times.push(baseTimestamp + (i * FabCeTimes.FORECAST_INTERVAL));
    i += 1;
  }
  return { ...fabCeData, times };
};

const reduceRouteData = (routeData) => {
  const reducedRoutes = routeData.map((route) => {
    // select only the first feature of type=CL_TRAJ
    const trajectory = route.features.filter(traj => traj.properties.type === 'CL_TRAJ')[0];
    const entry = route.features.filter(traj => traj.properties.type === 'CL_ENTRY')[0];
    return {
      entry,
      gufi: trajectory.properties.id,
      id: trajectory.properties.id,
      properties: trajectory.properties,
      trajectory,
    };
  });

  return reducedRoutes;
};

const initial = {
  isTurbWeatherVisible: true,
  visibleTurbWeatherSeverityLevel: WeatherSeverities.SEVERE,
  isTSWeatherVisible: true,
  visibleTSWeatherSeverityLevel: WeatherSeverities.SEVERE,
  isRdtWeatherVisible: true,
  visibleRdtWeatherSeverityLevel: Severities.LIGHT,
  isCatWeatherVisible: false,
  visibleCatWeatherSeverityLevel: Severities.LIGHT,
  isFabCeVisible: false,
  isNFAVisible: false,
  isFlowVisible: false,
  isRouteVisible: false,
  selectedFlights: [],
  selectedGufis: [],
  expandedGufis: [],
  selectedWaypoints: {},
  lateralReroutingMode: false,
  lateralReroutingData: { newTrajectorySegment: [], drawnTrajectory: [], gufi: '', measureId: -1, status: LateralReroutingModeStatus.NEW },
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case TOGGLE_RDT_WEATHER: {
      return { ...state, isRdtWeatherVisible: !state.isRdtWeatherVisible };
    }
    case SET_VISIBLE_RDT_WEATHER_SEVERITY_LEVEL: {
      return { ...state, visibleRdtWeatherSeverityLevel: action.severity };
    }
    case TOGGLE_CAT_WEATHER: {
      return { ...state, isCatWeatherVisible: !state.isCatWeatherVisible };
    }
    case TOGGLE_TS_WEATHER: {
      return { ...state, isTSWeatherVisible: !state.isTSWeatherVisible };
    }
    case TOGGLE_TURB_WEATHER: {
      return { ...state, isTurbWeatherVisible: !state.isTurbWeatherVisible };
    }
    case SET_VISIBLE_CAT_WEATHER_SEVERITY_LEVEL: {
      return { ...state, visibleCatWeatherSeverityLevel: action.severity };
    }
    case SET_VISIBLE_TS_WEATHER_SEVERITY_LEVEL: {
      return { ...state, visibleTSWeatherSeverityLevel: action.severity };
    }
    case SET_VISIBLE_TURB_WEATHER_SEVERITY_LEVEL: {
      return { ...state, visibleTurbWeatherSeverityLevel: action.severity };
    }
    case TOGGLE_FAB_CE: {
      return { ...state, isFabCeVisible: !state.isFabCeVisible };
    }
    case TOGGLE_NFA: {
      return { ...state, isNFAVisible: action.value };
    }
    case TOGGLE_FLOW: {
      return { ...state, isFlowVisible: action.value };
    }
    case TOGGLE_ROUTE: {
      return { ...state, isRouteVisible: action.value };
    }
    case LOADING_ROUTE_DATA: {
      return state;
    }
    case LOADING_ROUTE_DATA_SUCCESS: {
      return { ...state, routeData: reduceRouteData(action.routeData), routeDataError: undefined };
    }
    case LOADING_ROUTE_DATA_FAILURE: {
      return { ...state, routeDataError: action.error };
    }
    case LOADING_TRAJECTORY_AIRPORTS_DATA: {
      return state;
    }
    case LOADING_TRAJECTORY_AIRPORTS_DATA_SUCCESS: {
      const isSelected = state.selectedGufis.includes(action.gufi);
      const isExpanded = state.expandedGufis.includes(action.gufi);
      const isLR = (state.lateralReroutingData.gufi === action.gufi);
      if (isSelected || isExpanded || isLR) {
        const { data } = action;

        const trajectory = data.trajectory.features[0];
        const trajectoryPoints = data.trajectory.features.slice(1);

        const waypoints = trajectoryPoints.filter(p => p.properties.name
          && !p.properties.name.match(RegularExpressions.WAYPOINTS_CAL)
          && !p.properties.name.match(RegularExpressions.WAYPOINTS_APR));

        const aprPoints = trajectoryPoints.filter(p => p.properties.name
            && p.properties.name.match(RegularExpressions.WAYPOINTS_APR));
        const sortedByEtoAprPoints = aprPoints.sort((a, b) => (a.properties.eto > b.properties.eto ? 1 : -1));
        const lastAprPoint = Object.values(sortedByEtoAprPoints).pop();

        const waypointsOtherFlights = waypoints.reduce((acc, curr) => {
          const { name } = curr.properties;

          const waypointFlights = acc[name] ? acc[name].flights.filter(f => (f.gufi !== action.gufi)) : [];

          return {
            ...acc,
            [name]: {
              coordinates: curr.geometry.coordinates[0],
              flights: waypointFlights,
            },
          };
        }, state.selectedWaypoints);

        const waypointsReduced = waypoints.reduce((acc, curr) => {
          const { name, eto, fl } = curr.properties;
          const flightProperties = { eto, fl, gufi: action.gufi };

          const waypointFlights = acc[name] ? acc[name].flights.concat(flightProperties) : [flightProperties];

          return {
            ...acc,
            [name]: {
              coordinates: curr.geometry.coordinates[0],
              flights: waypointFlights,
            },
          };
        }, waypointsOtherFlights);

        const dataObj = {
          adep: data.adep ? data.adep : data.ades,
          ades: data.ades ? data.ades : data.adep,
          trajectory,
          trajectoryPoints,
          waypoints: waypoints.map(wp => wp.properties.name),
          lastAprPoint,
          gufi: action.gufi,
        };

        // If the flight is already in the selected list
        const indexSelected = state.selectedFlights.map(flight => flight.gufi).indexOf(action.gufi);
        if (indexSelected > -1) {
          const selectedFlights = state.selectedFlights.map(flight => (
            flight.gufi === action.gufi ?
              dataObj :
              flight
          ));
          return {
            ...state,
            selectedWaypoints: waypointsReduced,
            selectedFlights,
          };
        }

        // If the flight is selected for the first time
        return {
          ...state,
          selectedWaypoints: waypointsReduced,
          selectedFlights: [
            ...state.selectedFlights,
            dataObj,
          ],
        };
      }

      return state;
    }
    case LOADING_TRAJECTORY_AIRPORTS_DATA_FAILURE: {
      return { ...state, error: action.error };
    }
    case LOADING_FAB_CE_DATA: {
      return state;
    }
    case LOADING_FAB_CE_DATA_SUCCESS: {
      return { ...state, fabCeData: calculateFabCeTimestamps(action.fabCeData), fabCeDataError: undefined };
    }
    case LOADING_FAB_CE_DATA_FAILURE: {
      return { ...state, fabCeDataError: action.error };
    }
    case LOADING_FAB_CE_SECTOR: {
      return state;
    }
    case LOADING_FAB_CE_SECTOR_SUCCESS: {
      const { type, ...fabCeSector } = action;
      return { ...state, fabCeSector };
    }
    case LOADING_FAB_CE_SECTOR_FAILURE: {
      return { ...state, error: action.error };
    }
    case CLEAR_FAB_CE_SECTOR: {
      return { ...state, fabCeSector: undefined };
    }
    case MARKER_CLICKED: {
      let updateSelectedFlights = [];
      let updateSelectedWaypoints = {};
      if (action.gufi) {
        const updatedSelectedGufis = [action.gufi];
        return {
          ...state,
          clicked: action.gufi,
          selectedGufis: updatedSelectedGufis,
        };
      }
      if (state.expandedGufis.length > 0) {
        updateSelectedFlights = state.selectedFlights.filter(fl => state.expandedGufis.includes(fl.gufi));
        const flightToUnset = state.selectedFlights.filter(fl => state.selectedGufis.includes(fl.gufi) && !state.expandedGufis.includes(fl.gufi));
        const gufisToUnset = flightToUnset.map(fl => fl.gufi);
        const waypointsToUnset = uniq(flatMap(flightToUnset.map(fl => fl.waypoints)));
        updateSelectedWaypoints = waypointsToUnset.reduce((acc, wp) => {
          const waypoint = acc[wp];
          const flightsToKeep = waypoint.flights.filter(fl => !gufisToUnset.includes(fl.gufi));
          return { ...acc, [wp]: flightsToKeep.length > 0 ? { ...waypoint, flights: flightsToKeep } : undefined };
        }, state.selectedWaypoints);
      }

      return {
        ...state,
        clicked: action.gufi,
        selectedGufis: [],
        selectedFlights: updateSelectedFlights,
        selectedWaypoints: updateSelectedWaypoints,
      };
    }
    case FLIGHT_LIST_ROW_CLICKED: {
      const updatedSelectedGufis = [...state.selectedGufis];
      let updateSelectedFlights = [...state.selectedFlights];
      let updateSelectedWaypoints = state.selectedWaypoints;
      const indexOfClicked = updatedSelectedGufis.indexOf(action.gufi);
      const gufiSelected = updatedSelectedGufis[indexOfClicked];

      if (indexOfClicked < 0 && action.gufi) {
        updatedSelectedGufis.push(action.gufi);
        return {
          ...state,
          clicked: action.gufi,
          selectedGufis: updatedSelectedGufis,
        };
      }

      if (!state.expandedGufis.includes(gufiSelected)) {
        const flight = updateSelectedFlights.find(fl => fl.gufi === gufiSelected);
        if (flight) {
          const { waypoints } = flight;
          updateSelectedWaypoints = waypoints.reduce((acc, wp) => {
            const waypoint = updateSelectedWaypoints[wp];
            if (waypoint.flights.length > 1) {
              return { ...acc, [wp]: { ...waypoint, flights: waypoint.flights.filter(fl => fl.gufi !== gufiSelected) } };
            }
            return { ...acc, [wp]: undefined };
          }, updateSelectedWaypoints);
          updateSelectedFlights = updateSelectedFlights.filter(fl => fl.gufi !== gufiSelected);
        }
      }

      if (indexOfClicked >= 0) {
        updatedSelectedGufis.splice(indexOfClicked, 1);
      }

      return {
        ...state,
        clicked: '',
        selectedGufis: updatedSelectedGufis,
        selectedFlights: updateSelectedFlights,
        selectedWaypoints: updateSelectedWaypoints,
      };
    }
    case FLIGHT_LIST_ROW_EXPANDED: {
      let updateSelectedWaypoints = state.selectedWaypoints;
      let updateSelectedFlights = [...state.selectedFlights];
      const updatedExpandedGufis = [...state.expandedGufis];
      const indexOfExpanded = updatedExpandedGufis.indexOf(action.gufi);
      const gufiExpanded = updatedExpandedGufis[indexOfExpanded];

      if (indexOfExpanded < 0 && action.gufi) {
        updatedExpandedGufis.push(action.gufi);
        return {
          ...state,
          expandedGufis: updatedExpandedGufis,
        };
      }

      const selectedFlight = updateSelectedFlights.find(fl => fl.gufi === gufiExpanded);

      if (selectedFlight && !state.selectedGufis.includes(gufiExpanded)) {
        updateSelectedWaypoints = selectedFlight.waypoints.reduce((acc, wp) => {
          const waypoint = updateSelectedWaypoints[wp];
          if (waypoint.flights.length > 1) {
            return { ...acc, [wp]: { ...waypoint, flights: waypoint.flights.filter(fl => fl.gufi !== gufiExpanded) } };
          }
          return { ...acc, [wp]: undefined };
        }, updateSelectedWaypoints);
        updateSelectedFlights = updateSelectedFlights.filter(fl => fl.gufi !== gufiExpanded);
      }

      updatedExpandedGufis.splice(indexOfExpanded, 1);
      return {
        ...state,
        selectedFlights: updateSelectedFlights,
        selectedWaypoints: updateSelectedWaypoints,
        expandedGufis: updatedExpandedGufis,
      };
    }
    case TOGGLE_LATERAL_REROUTING_MODE: {
      return { ...state, lateralReroutingMode: !state.lateralReroutingMode };
    }
    case UPDATE_LATERAL_REROUTING_DATA: {
      return {
        ...state,
        lateralReroutingData: {
          ...state.lateralReroutingData,
          ...action.data,
        },
      };
    }
    default: {
      return state;
    }
  }
};
