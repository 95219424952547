import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import airspaces from './airspaces/reducers';
import api from './api/reducers';
import approach from './approach/reducers';
import asd from './asd/reducers';
import authentication from './authentication/reducers';
import capaPlans from './capaPlans/reducers';
import cat from './cat/reducers';
import cdm from './cdm/reducers';
import charts from './charts/reducers';
import definedAreas from './definedAreas/reducers';
import entry from './entry/reducers';
import featureFlags from './featureFlags/reducers';
import flightData from './flightData/reducers';
import flightsList from './flightsList/reducers';
import flows from './flow/reducers';
import forecast from './forecast/reducers';
import hotspots from './hotspots/reducers';
import kpis from './kpis/reducers';
import licenses from './licenses/reducers';
import nm from './nm/reducers';
import notam from './notam/reducers';
import occupancy from './occupancy/reducers';
import pretact from './pretact/reducers';
import rdt from './rdt/reducers';
import replay from './replay/reducers';
import reports from './reports/reducers';
import sectorsData from './sectors/reducers';
import settings from './settings/reducers';
import stams from './stams/reducers';
import status from './status/reducers';
import tfv from './tfv/reducers';
import time from './time/reducers';
import timeline from './timeline/reducers';
import user from './user/reducers';
import weather from './weather/reducers';
import whatif from './whatif/reducers';

const rootReducer = combineReducers({
  session: authentication,
  airspaces,
  api,
  approach,
  asd,
  capaPlans,
  cat,
  cdm,
  charts,
  definedAreas,
  entry,
  featureFlags,
  flightData,
  flightsList,
  flows,
  forecast,
  hotspots,
  kpis,
  licenses,
  notam,
  occupancy,
  pretact,
  rdt,
  replay,
  reports,
  sectorsData,
  settings,
  stams,
  tfv,
  time,
  user,
  weather,
  whatif,
  status,
  timeline,
  nm,
});

const initialState = {};

const thunkMiddleware = applyMiddleware(thunk);
const reduxDevToolsEnabled = process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__;
const reduxMiddleware = reduxDevToolsEnabled
  ? compose(thunkMiddleware, window.__REDUX_DEVTOOLS_EXTENSION__())
  : compose(thunkMiddleware);

export default (initialStateArg = initialState) => createStore(rootReducer, initialStateArg, reduxMiddleware);
