/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import updateNMPlan from './actions';

const initialState = {
  loading: false,
};

const handleError = (state, { payload }) => {
  state.error = payload;
};

const nmSlice = createSlice({
  name: 'nm',
  initialState,
  extraReducers: {
    [updateNMPlan.pending]: (state) => {
      state.loading = true;
    },
    [updateNMPlan.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateNMPlan.rejected]: (state, {
      payload }) => {
      state.loading = false;
      handleError(state, payload);
    },
  },
});

export default nmSlice.reducer;
