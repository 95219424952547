import {
  queryConfigsData,
  queryPossibleConfs,
  callUpdateSectorsConf,
  queryRelationshipData,
} from './services';

export const UPDATE_SELECTED_CTA = 'UPDATE_SELECTED_CTA';
export function updateSelectedCTA(cta) {
  return {
    type: UPDATE_SELECTED_CTA,
    cta,
  };
}

export const SELECT_SECTO = 'SELECT_SECTO';
export function selectSecto(sectoData) {
  return {
    type: SELECT_SECTO,
    sectoData,
  };
}

export const CLEAR_SECTO = 'CLEAR_SECTO';
export function clearSecto() {
  return {
    type: CLEAR_SECTO,
  };
}

export const LOADING_RELATIONSHIP_DATA = 'LOADING_RELATIONSHIP_DATA';
export function loadingRelationshipData() {
  return {
    type: LOADING_RELATIONSHIP_DATA,
  };
}

export const LOADING_RELATIONSHIP_DATA_SUCCESS = 'LOADING_RELATIONSHIP_DATA_SUCCESS';
export function loadingRelationshipDataSuccess(relationshipData) {
  return {
    type: LOADING_RELATIONSHIP_DATA_SUCCESS,
    relationshipData,
  };
}

export const CLEAR_RELATIONSHIP_DATA = 'CLEAR_RELATIONSHIP_DATA';
export function clearRelationshipData() {
  return {
    type: CLEAR_RELATIONSHIP_DATA,
  };
}

export const LOADING_RELATIONSHIP_DATA_FAILURE = 'LOADING_RELATIONSHIP_DATA_FAILURE';
export function loadingRelationshipDataFailure(error) {
  return {
    type: LOADING_RELATIONSHIP_DATA_FAILURE,
    error,
  };
}

export const LOADING_SECTORS_DATA = 'LOADING_SECTORS_DATA';
export function loadingSectorsData() {
  return {
    type: LOADING_SECTORS_DATA,
  };
}

export const LOADING_SECTORS_DATA_SUCCESS = 'LOADING_SECTORS_DATA_SUCCESS';
export function loadingSectorsDataSuccess(sectorsData) {
  return {
    type: LOADING_SECTORS_DATA_SUCCESS,
    sectorsData,
  };
}

export const LOADING_SECTORS_DATA_FAILURE = 'LOADING_SECTORS_DATA_FAILURE';
export function loadingSectorsDataFailure(error) {
  return {
    type: LOADING_SECTORS_DATA_FAILURE,
    error,
  };
}

export const LOADING_POSSIBLE_CONFS = 'LOADING_POSSIBLE_CONFS';
export function loadingPossibleConfs() {
  return {
    type: LOADING_POSSIBLE_CONFS,
  };
}

export const LOADING_POSSIBLE_CONFS_SUCCESS = 'LOADING_POSSIBLE_CONFS_SUCCESS';
export function loadingPossibleConfsSuccess(possibleConfs) {
  return {
    type: LOADING_POSSIBLE_CONFS_SUCCESS,
    possibleConfs,
  };
}

export const LOADING_POSSIBLE_CONFS_FAILURE = 'LOADING_POSSIBLE_CONFS_FAILURE';
export function loadingPossibleConfsFailure(error) {
  return {
    type: LOADING_POSSIBLE_CONFS_FAILURE,
    error,
  };
}

export const UPDATING_SECTORS_CONF = 'UPDATING_SECTORS_CONF';
export function updatingSectorsConf() {
  return {
    type: UPDATING_SECTORS_CONF,
  };
}

export const UPDATING_SECTORS_CONF_SUCCESS = 'UPDATING_SECTORS_CONF_SUCCESS';
export function updatingSectorsConfSuccess(sectorsData) {
  return {
    type: UPDATING_SECTORS_CONF_SUCCESS,
    sectorsData,
  };
}

export const UPDATING_SECTORS_CONF_FAILURE = 'UPDATING_SECTORS_CONF_FAILURE';
export function updatingSectorsConfFailure(error) {
  return {
    type: UPDATING_SECTORS_CONF_FAILURE,
    error,
  };
}

export const SECTOR_EDIT_ERROR = 'SECTOR_EDIT_ERROR';
export function sectorEditError(errorMessage) {
  return {
    type: SECTOR_EDIT_ERROR,
    errorMessage,
  };
}

export const CLEAR_SECTOR_EDIT_ERROR = 'CLEAR_SECTOR_EDIT_ERROR';
export function clearSectorEditError() {
  return {
    type: CLEAR_SECTOR_EDIT_ERROR,
  };
}

export const SELECTED_CONFIG = 'SELECTED_CONFIG';
export function configSelected(selectedConfigData) {
  return {
    type: SELECTED_CONFIG,
    selectedConfigData,
  };
}

export const CLEAR_SELECTED_CONFIG = 'CLEAR_SELECTED_CONFIG';
export function clearConfigSelected() {
  return {
    type: CLEAR_SELECTED_CONFIG,
  };
}

export const CHANGED_CURRENT_PLAN = 'CHANGED_CURRENT_PLAN';
export function changedCurrentPlan(newPlan) {
  return {
    type: CHANGED_CURRENT_PLAN,
    newPlan,
  };
}

/* Async Action Creators */
export const fetchRelationshipData = (sectoName, confName, remainingSectors, from, to) => (dispatch) => {
  dispatch(loadingRelationshipData());
  return queryRelationshipData(sectoName, confName, remainingSectors, from, to)
    .then(data => dispatch(loadingRelationshipDataSuccess(data)))
    .catch(error => dispatch(loadingRelationshipDataFailure(error)));
};

export const fetchConfigsData = (sector, from, to) => (dispatch) => {
  dispatch(loadingSectorsData());
  return queryConfigsData(sector, from, to)
    .then(data => dispatch(loadingSectorsDataSuccess(data)))
    .catch(error => dispatch(loadingSectorsDataFailure(error)));
};

export const fetchPossibleConfs = sectorName => (dispatch) => {
  dispatch(loadingPossibleConfs());
  return queryPossibleConfs(sectorName)
    .then(data => dispatch(loadingPossibleConfsSuccess(data)))
    .catch(error => dispatch(loadingPossibleConfsFailure(error)));
};

export const updateSectorsConf = (sectoPlanName, conf, day) => (dispatch) => {
  dispatch(updatingSectorsConf());
  return callUpdateSectorsConf(sectoPlanName, conf, day)
    .then(data => dispatch(updatingSectorsConfSuccess(data)))
    .catch(error => dispatch(updatingSectorsConfFailure(error)));
};
