import {
  LOADING_CHARTS_SETTINGS,
  LOADING_CHARTS_SETTINGS_SUCCESS,
  LOADING_CHARTS_SETTINGS_FAILURE,
  UPDATING_CHARTS_OCC,
  UPDATING_CHARTS_OCC_FAILURE,
  UPDATING_CHARTS_OCC_SUCCESS,
  UPDATING_CHARTS_ENTRY,
  UPDATING_CHARTS_ENTRY_SUCCESS,
  UPDATING_CHARTS_ENTRY_FAILURE,
} from './actions';
import { Time } from '../../constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case LOADING_CHARTS_SETTINGS: {
      return state;
    }
    case LOADING_CHARTS_SETTINGS_SUCCESS: {
      return {
        ...state,
        entryStep: (action.entryStep / Time.MINUTE_IN_MILISECONDS),
        occStep: (action.occStep / Time.MINUTE_IN_MILISECONDS),
        entryDuration: (action.entryDuration / Time.MINUTE_IN_MILISECONDS),
        occDuration: (action.occDuration / Time.MINUTE_IN_MILISECONDS),
      };
    }
    case LOADING_CHARTS_SETTINGS_FAILURE: {
      return { ...state, error: action.error };
    }
    case UPDATING_CHARTS_OCC: {
      return { ...state, isUpdatingOcc: true };
    }
    case UPDATING_CHARTS_OCC_SUCCESS: {
      return {
        ...state,
        occStep: (action.occStep / Time.MINUTE_IN_MILISECONDS),
        occDuration: (action.occDuration / Time.MINUTE_IN_MILISECONDS),
        isUpdatingOcc: false,
      };
    }
    case UPDATING_CHARTS_OCC_FAILURE: {
      return { ...state, error: action.error };
    }
    case UPDATING_CHARTS_ENTRY: {
      return { ...state, isUpdatingEntry: true };
    }
    case UPDATING_CHARTS_ENTRY_SUCCESS: {
      return {
        ...state,
        entryStep: (action.entryStep / Time.MINUTE_IN_MILISECONDS),
        entryDuration: (action.entryDuration / Time.MINUTE_IN_MILISECONDS),
        isUpdatingEntry: false,
      };
    }
    case UPDATING_CHARTS_ENTRY_FAILURE: {
      return { ...state, error: action.error };
    }
    default: {
      return state;
    }
  }
};
