import queryRdtData from './services';

export const LOADING_RDT_DATA = 'LOADING_RDT_DATA';
export function loadingRdtData() {
  return {
    type: LOADING_RDT_DATA,
  };
}

export const LOADING_RDT_DATA_SUCCESS = 'LOADING_RDT_DATA_SUCCESS';
export function loadingRdtDataSuccess(rdtData) {
  return {
    type: LOADING_RDT_DATA_SUCCESS,
    rdtData,
  };
}

export const LOADING_RDT_DATA_FAILURE = 'LOADING_RDT_DATA_FAILURE';
export function loadingRdtDataFailure(error) {
  return {
    type: LOADING_RDT_DATA_FAILURE,
    error,
  };
}

/* Async Action Creators */
export const fetchRdtData = (atTime, severity) => (dispatch) => {
  dispatch(loadingRdtData());
  return queryRdtData(atTime, severity).then((data) => {
    dispatch(loadingRdtDataSuccess(data));
  }).catch((error) => {
    dispatch(loadingRdtDataFailure(error));
  });
};
