import callApi from '../api/services';
import { CapaPlansPaths } from '../../constants';

const queryCapaPlansData = (path, fir, tfvs, step, duration, start, end) => callApi(
  `${path}/${fir}/${tfvs.join(',')}?from=${start}&to=${end}${step ? `&step=${step}` : ''}${duration ? `&duration=${duration}` : ''}`,
  { basePath: window.env.CAPA_PLANS_API_BASE_URL },
);

export const queryCapaPlansEntryData = (tfv, from, to) => callApi(
  `${CapaPlansPaths.CAPA_PLAN_ENTRY}/${tfv}?from=${from}&to=${to}&source=USER`,
  { basePath: window.env.CAPA_PLANS_API_BASE_URL },
);

export const queryCapaPlansOccData = (tfv, from, to) => callApi(
  `${CapaPlansPaths.CAPA_PLAN_OCC}/${tfv}?from=${from}&to=${to}&source=USER`,
  { basePath: window.env.CAPA_PLANS_API_BASE_URL },
);

export const putCapaPlanOccData = (fir, tfv, day, plan) => callApi(
  `${CapaPlansPaths.CAPA_PLAN_OCC}/${fir}/${tfv}?day=${day}&source=USER`,
  { basePath: window.env.CAPA_PLANS_API_BASE_URL, method: 'PUT', body: plan },
);

export const putCapaPlanEntryData = (fir, tfv, day, plan) => callApi(
  `${CapaPlansPaths.CAPA_PLAN_ENTRY}/${fir}/${tfv}?day=${day}&source=USER`,
  { basePath: window.env.CAPA_PLANS_API_BASE_URL, method: 'PUT', body: plan },
);

export default queryCapaPlansData;
