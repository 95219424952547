import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { ErrorHandler } from '@atfm/utils';
import { useAuthContext } from '../Context/AuthContext';

const LoginPage = () => {
  const history = useHistory();
  const { path } = useParams();
  const { hash } = useLocation();
  const { initialization } = useAuthContext();

  useEffect(() => {
    const checkRedirectPath = async () => {
      try {
        await initialization;

        if (path) {
          history.push(`/${path}${hash}`);
        } else {
          history.push('/');
        }
      } catch (e) {
        ErrorHandler.logError(e);
      }
    };

    checkRedirectPath();
  }, []);

  return (null);
};

export default LoginPage;
