import queryFlightsData from './services';

export const LOADING_FLIGHT_DATA = 'LOADING_FLIGHT_DATA';
export function loadingFlightData() {
  return {
    type: LOADING_FLIGHT_DATA,
  };
}

export const LOADING_FLIGHT_DATA_SUCCESS = 'LOADING_FLIGHT_DATA_SUCCESS';
export function loadingFlightDataSuccess(flightData) {
  return {
    type: LOADING_FLIGHT_DATA_SUCCESS,
    flightData,
  };
}

export const LOADING_FLIGHT_DATA_FAILURE = 'LOADING_FLIGHT_DATA_FAILURE';
export function loadingFlightDataFailure(error) {
  return {
    type: LOADING_FLIGHT_DATA_FAILURE,
    error,
  };
}

/* Async Action Creators */
export const fetchFlightsPositionsData = (flights, atTime, sessionId) => (dispatch) => {
  dispatch(loadingFlightData());

  const gufis = [];
  flights.forEach((flight) => {
    gufis.push(flight.gufi);
  });

  return queryFlightsData(gufis, atTime, sessionId)
    .then(positions => dispatch(loadingFlightDataSuccess(positions)))
    .catch(err => dispatch(loadingFlightDataFailure(err)));
};
