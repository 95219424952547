import callApi from '../api/services';
import { XuningPaths } from '../../constants';

export const queryChartsSettings = () =>
  callApi(`${XuningPaths.CHARTS}/chartSettings`, { basePath: window.env.XUNING_API_BASE_URL })
    .then((data) => {
      if (data) {
        return {
          occStep: data.occ_step,
          occDuration: data.occ_duration,
          entryStep: data.entry_step,
          entryDuration: data.entry_duration,
        };
      }
      return null;
    });

export const putChartsOcc = occ =>
  callApi(`${XuningPaths.CHARTS}/occ`, { basePath: window.env.XUNING_API_BASE_URL, body: occ, method: 'PUT' })
    .then((data) => {
      if (data) {
        return {
          occStep: data.occ_step,
          occDuration: data.occ_duration,
        };
      }
      return null;
    });

export const putChartsEntry = entry =>
  callApi(`${XuningPaths.CHARTS}/entry`, { basePath: window.env.XUNING_API_BASE_URL, body: entry, method: 'PUT' })
    .then((data) => {
      if (data) {
        return {
          entryStep: data.entry_step,
          entryDuration: data.entry_duration,
        };
      }
      return null;
    });
