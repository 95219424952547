import callApi from '../api/services';
import { AirportsPaths, FlightsPaths, FabCePaths, FlowSpotsPaths } from '../../constants';

export const queryAirportsData = icao => callApi(`${AirportsPaths.AIRPORTS}${icao}`, { basePath: window.env.AIRPORTS_API_BASE_URL });

export const queryTrajectoriesData = (gufi, sessionId) =>
  callApi(`${FlightsPaths.TRAJECTORIES}/${gufi}/waypoints?format=geojson${sessionId ? `&sessionId=${sessionId}` : ''}`, { basePath: window.env.FLIGHTS_API_BASE_URL });

export const queryFabCeData = fir =>
  callApi(`${FabCePaths.FAB_CE}/${fir}`, { basePath: window.env.FAB_CE_API_BASE_URL });

export const queryFabCeSector = (fir, sector) =>
  callApi(`static/fab-ce/${fir}/${sector}.json`, { basePath: window.location.href });

export const queryRouteData = (fir, day) =>
  callApi(`${FlowSpotsPaths.CLUSTERS}/${fir}/${day}?format=geojson`, { basePath: window.env.API_BASE_URL });
