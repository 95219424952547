import queryCatData from './services';

export const LOADING_CAT_DATA = 'LOADING_CAT_DATA';
export function loadingCatData() {
  return {
    type: LOADING_CAT_DATA,
  };
}

export const LOADING_CAT_DATA_SUCCESS = 'LOADING_CAT_DATA_SUCCESS';
export function loadingCatDataSuccess(catData) {
  return {
    type: LOADING_CAT_DATA_SUCCESS,
    catData,
  };
}

export const LOADING_CAT_DATA_FAILURE = 'LOADING_CAT_DATA_FAILURE';
export function loadingCatDataFailure(error) {
  return {
    type: LOADING_CAT_DATA_FAILURE,
    error,
  };
}

/* Async Action Creators */
export const fetchCatData = (atTime, severity) => (dispatch) => {
  dispatch(loadingCatData());
  return queryCatData(atTime, severity).then((data) => {
    dispatch(loadingCatDataSuccess(data));
  }).catch((error) => {
    dispatch(loadingCatDataFailure(error));
  });
};
