import { createAsyncThunk } from '@reduxjs/toolkit';
import queryAirspacesData from './services';

// Create the async thunk
const fetchAirspacesData = createAsyncThunk(
  'FETCH_AIRSPACES_DATA', // Action type
  async (neighborsList, { rejectWithValue }) => {
    try {
      const allPromises = neighborsList.map(neighbor => queryAirspacesData(neighbor));
      const data = await Promise.all(allPromises);
      return data; // This will be the fulfilled payload
    } catch (error) {
      return rejectWithValue(error); // This will handle the rejected payload
    }
  },
);

export default fetchAirspacesData;
