import queryCapaPlansData, { queryCapaPlansOccData, queryCapaPlansEntryData, putCapaPlanEntryData, putCapaPlanOccData } from './services';

export const LOADING_CAPA_PLANS_DATA = 'LOADING_CAPA_PLANS_DATA';
export function loadingCapaPlansData(requestData) {
  return {
    type: LOADING_CAPA_PLANS_DATA,
    requestData,
  };
}

export const LOADING_CAPA_PLANS_DATA_SUCCESS = 'LOADING_CAPA_PLANS_DATA_SUCCESS';
export function loadingCapaPlansDataSuccess(capaPlansData, requestData) {
  return {
    type: LOADING_CAPA_PLANS_DATA_SUCCESS,
    capaPlansData,
    requestData,
  };
}

export const LOADING_CAPA_PLANS_DATA_FAILURE = 'LOADING_CAPA_PLANS_DATA_FAILURE';
export function loadingCapaPlansDataFailure(error) {
  return {
    type: LOADING_CAPA_PLANS_DATA_FAILURE,
    error,
  };
}

export const ADD_CAPA_PLANS_OCC_DATA = 'ADD_CAPA_PLANS_OCC_DATA';
export function addCapaPlansOccData(occCapaPlan) {
  return {
    type: ADD_CAPA_PLANS_OCC_DATA,
    occCapaPlan,
  };
}

export const LOADING_CAPA_PLANS_OCC_DATA = 'LOADING_CAPA_PLANS_OCC_DATA';
export function loadingCapaPlansOccData() {
  return {
    type: LOADING_CAPA_PLANS_OCC_DATA,
  };
}

export const LOADING_CAPA_PLANS_DATA_OCC_SUCCESS = 'LOADING_CAPA_PLANS_OCC_DATA_SUCCESS';
export function loadingCapaPlansOccDataSuccess(capaPlansOccData) {
  return {
    type: LOADING_CAPA_PLANS_DATA_OCC_SUCCESS,
    capaPlansOccData,
  };
}

export const LOADING_CAPA_PLANS_DATA_OCC_FAILURE = 'LOADING_CAPA_PLANS_DATA_OCC_FAILURE';
export function loadingCapaPlansOccDataFailure(error) {
  return {
    type: LOADING_CAPA_PLANS_DATA_OCC_FAILURE,
    error,
  };
}

export const PUT_CAPA_PLANS_OCC_DATA = 'PUT_CAPA_PLANS_OCC_DATA';
export function putCapaPlansOccData() {
  return {
    type: PUT_CAPA_PLANS_OCC_DATA,
  };
}

export const PUT_CAPA_PLANS_OCC_DATA_SUCCESS = 'PUT_CAPA_PLANS_OCC_DATA_SUCCESS';
export function putCapaPlansOccDataSuccess(data) {
  return {
    type: PUT_CAPA_PLANS_OCC_DATA_SUCCESS,
    data,
  };
}

export const PUT_CAPA_PLANS_OCC_DATA_FAILURE = 'PUT_CAPA_PLANS_OCC_DATA_FAILURE';
export function putCapaPlansOccDataFailure(tfv, day, err) {
  return {
    type: PUT_CAPA_PLANS_OCC_DATA_FAILURE,
    tfv,
    date: day,
    err,
  };
}

export const CLEAR_OCC_ERRORS_PUT = 'CLEAR_OCC_ERRORS_PUT';
export function clearOccErrorsPut() {
  return {
    type: CLEAR_OCC_ERRORS_PUT,
  };
}

export const LOADING_CAPA_PLANS_ENTRY_DATA = 'LOADING_CAPA_PLANS_ENTRY_DATA';
export function loadingCapaPlansEntryData() {
  return {
    type: LOADING_CAPA_PLANS_ENTRY_DATA,
  };
}

export const LOADING_CAPA_PLANS_ENTRY_DATA_SUCCESS = 'LOADING_CAPA_PLANS_ENTRY_SUCCESS';
export function loadingCapaPlansEntryDataSuccess(capaPlansEntryData) {
  return {
    type: LOADING_CAPA_PLANS_ENTRY_DATA_SUCCESS,
    capaPlansEntryData,
  };
}

export const LOADING_CAPA_PLANS_ENTRY_DATA_FAILURE = 'LOADING_CAPA_PLANS_ENTRY_DATA_FAILURE';
export function loadingCapaPlansEntryDataFailure(error) {
  return {
    type: LOADING_CAPA_PLANS_ENTRY_DATA_FAILURE,
    error,
  };
}

export const PUT_CAPA_PLANS_ENTRY_DATA = 'PUT_CAPA_PLANS_ENTRY_DATA';
export function putCapaPlansEntryData() {
  return {
    type: PUT_CAPA_PLANS_ENTRY_DATA,
  };
}

export const PUT_CAPA_PLANS_ENTRY_DATA_SUCCESS = 'PUT_CAPA_PLANS_ENTRY_DATA_SUCCESS';
export function putCapaPlansEntryDataSuccess(data) {
  return {
    type: PUT_CAPA_PLANS_ENTRY_DATA_SUCCESS,
    data,
  };
}

export const PUT_CAPA_PLANS_ENTRY_DATA_FAILURE = 'PUT_CAPA_PLANS_ENTRY_DATA_FAILURE';
export function putCapaPlansEntryDataFailure(tfv, day, err) {
  return {
    type: PUT_CAPA_PLANS_ENTRY_DATA_FAILURE,
    tfv,
    date: day,
    err,
  };
}

export const CLEAR_ENTRY_ERRORS_PUT = 'CLEAR_ENTRY_ERRORS_PUT';
export function clearEntryErrorsPut() {
  return {
    type: CLEAR_ENTRY_ERRORS_PUT,
  };
}

/* Async Action Creators */
export const fetchCapaPlansData = (path, fir, tfvs, step, duration, start, end, isComparison, tfvType, isHotspot, isSectoPlanning) => (dispatch) => {
  const requestData = {
    path, fir, tfvs, step, duration, start, end, isComparison, tfvType, isHotspot, isSectoPlanning,
  };
  dispatch(loadingCapaPlansData(requestData));

  return queryCapaPlansData(path, fir, tfvs, step, duration, start, end)
    .then((capaPlans) => {
      dispatch(loadingCapaPlansDataSuccess(capaPlans, requestData));
    }).catch((err) => {
      dispatch(loadingCapaPlansDataFailure(err));
    });
};

export const fetchCapaPlansOccData = (tfv, from, to) => (dispatch) => {
  dispatch(loadingCapaPlansOccData());

  return queryCapaPlansOccData(tfv, from, to)
    .then((capaPlansOcc) => {
      dispatch(loadingCapaPlansOccDataSuccess(capaPlansOcc));
    }).catch((err) => {
      dispatch(loadingCapaPlansOccDataFailure(err));
    });
};

export const fetchCapaPlansEntryData = (tfv, from, to) => (dispatch) => {
  dispatch(loadingCapaPlansEntryData());

  return queryCapaPlansEntryData(tfv, from, to)
    .then((capaPlansEntry) => {
      dispatch(loadingCapaPlansEntryDataSuccess(capaPlansEntry));
    }).catch((err) => {
      dispatch(loadingCapaPlansEntryDataFailure(err));
    });
};

export const setCapaPlansEntryData = (fir, tfv, day, plan) => (dispatch) => {
  dispatch(putCapaPlansEntryData());

  return putCapaPlanEntryData(fir, tfv, day, plan)
    .then(() => {
      dispatch(putCapaPlansEntryDataSuccess({ fir, tfv, day, plan }));
    }).catch((err) => {
      dispatch(putCapaPlansEntryDataFailure(tfv, day, err.body));
    });
};

export const setCapaPlansOccData = (fir, tfv, day, plan) => (dispatch) => {
  dispatch(putCapaPlansOccData());

  return putCapaPlanOccData(fir, tfv, day, plan)
    .then(() => {
      dispatch(putCapaPlansOccDataSuccess({ fir, tfv, day, plan }));
    }).catch((err) => {
      dispatch(putCapaPlansOccDataFailure(tfv, day, err.body));
    });
};
