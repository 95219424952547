import { queryChartsSettings, putChartsOcc, putChartsEntry } from './services';
import { Time } from '../../constants';

export const LOADING_CHARTS_SETTINGS = 'LOADING_CHARTS_SETTINGS';
export function loadingChartsSettings() {
  return {
    type: LOADING_CHARTS_SETTINGS,
  };
}

export const LOADING_CHARTS_SETTINGS_SUCCESS = 'LOADING_CHARTS_SETTINGS_SUCCESS';
export function loadingChartsSettingsSuccess({
  occStep, entryStep, occDuration, entryDuration,
}) {
  return {
    type: LOADING_CHARTS_SETTINGS_SUCCESS,
    occDuration,
    occStep,
    entryDuration,
    entryStep,
  };
}

export const LOADING_CHARTS_SETTINGS_FAILURE = 'LOADING_CHARTS_SETTINGS_FAILURE';
export function loadingChartsSettingsFailure(error) {
  return {
    type: LOADING_CHARTS_SETTINGS_FAILURE,
    error,
  };
}

export const fetchChartsSettings = () => (dispatch) => {
  dispatch(loadingChartsSettings());
  return queryChartsSettings()
    .then(data => dispatch(loadingChartsSettingsSuccess(data)))
    .catch(error => dispatch(loadingChartsSettingsFailure(error)));
};

export const UPDATING_CHARTS_OCC = 'UPDATING_CHARTS_OCC';
export function updatingChartsOcc() {
  return {
    type: UPDATING_CHARTS_OCC,
  };
}

export const UPDATING_CHARTS_OCC_SUCCESS = 'UPDATING_CHARTS_OCC_SUCCESS';
export function updatingChartsOccSuccess({
  occStep, occDuration,
}) {
  return {
    type: UPDATING_CHARTS_OCC_SUCCESS,
    occStep,
    occDuration,
  };
}

export const UPDATING_CHARTS_OCC_FAILURE = 'UPDATING_CHARTS_OCC_FAILURE';
export function updatingChartsOccFailure(error) {
  return {
    type: UPDATING_CHARTS_OCC_FAILURE,
    error,
  };
}

export const updateChartsOcc = (duration, step) => (dispatch) => {
  const occ = { occ_step: step * Time.MINUTE_IN_MILISECONDS, occ_duration: duration * Time.MINUTE_IN_MILISECONDS };
  dispatch(updatingChartsOcc());
  return putChartsOcc(occ)
    .then(data => dispatch(updatingChartsOccSuccess(data)))
    .catch(error => dispatch(updatingChartsOccFailure(error)));
};

export const UPDATING_CHARTS_ENTRY = 'UPDATING_CHARTS_ENTRY';
export function updatingChartsEntry() {
  return {
    type: UPDATING_CHARTS_ENTRY,
  };
}

export const UPDATING_CHARTS_ENTRY_SUCCESS = 'UPDATING_CHARTS_ENTRY_SUCCESS';
export function updatingChartsEntrySuccess({
  entryStep, entryDuration,
}) {
  return {
    type: UPDATING_CHARTS_ENTRY_SUCCESS,
    entryStep,
    entryDuration,
  };
}

export const UPDATING_CHARTS_ENTRY_FAILURE = 'UPDATING_CHARTS_ENTRY_FAILURE';
export function updatingChartsEntryFailure(error) {
  return {
    type: UPDATING_CHARTS_ENTRY_FAILURE,
    error,
  };
}

export const updateChartsEntry = (duration, step) => (dispatch) => {
  const entry = { entry_step: step * Time.MINUTE_IN_MILISECONDS, entry_duration: duration * Time.MINUTE_IN_MILISECONDS };
  dispatch(updatingChartsEntry());
  return putChartsEntry(entry)
    .then(data => dispatch(updatingChartsEntrySuccess(data)))
    .catch(error => dispatch(updatingChartsEntryFailure(error)));
};
