import { createAsyncThunk } from '@reduxjs/toolkit';
import { queryNoFlightAreas, queryUserDefinedAreas, addDefinedArea, deleteDefinedAreas, patchDefinedAreas } from './services';

export const TOGGLE_NO_FLIGHT_AREAS = 'TOGGLE_NO_FLIGHT_AREAS';
export function toggleNoFlightAreas() {
  return {
    type: TOGGLE_NO_FLIGHT_AREAS,
  };
}

export const TOGGLE_USER_DEFINED_AREAS = 'TOGGLE_USER_DEFINED_AREAS';
export function toggleUserDefinedAreas() {
  return {
    type: TOGGLE_USER_DEFINED_AREAS,
  };
}

export const CHANGE_NO_FLIGHT_AREAS_FILTER = 'CHANGE_NO_FLIGHT_AREAS_FILTER';
export function changeNoFlightAreasFilter(filter) {
  return {
    type: CHANGE_NO_FLIGHT_AREAS_FILTER,
    filter,
  };
}

export const CHANGE_USER_DEFINED_AREAS_FILTER = 'CHANGE_USER_DEFINED_AREAS_FILTER';
export function changeUserDefinedAreasFilter(filter) {
  return {
    type: CHANGE_USER_DEFINED_AREAS_FILTER,
    filter,
  };
}

export const CREATE_LOCAL_DEFINED_AREA = 'CREATE_LOCAL_DEFINED_AREA';
export function createLocalDefinedArea(geoJson) {
  return {
    type: CREATE_LOCAL_DEFINED_AREA,
    geoJson,
  };
}

export const CHANGE_DEFINED_AREA_IN_EDIT = 'CHANGE_DEFINED_AREA_IN_EDIT';
export function changeDefinedAreaInEdit(definedArea) {
  return {
    type: CHANGE_DEFINED_AREA_IN_EDIT,
    definedArea,
  };
}

export const CLEAR_DEFINED_AREA_IN_EDIT = 'CLEAR_DEFINED_AREA_IN_EDIT';
export function clearDefinedAreaInEdit() {
  return {
    type: CLEAR_DEFINED_AREA_IN_EDIT,
  };
}

export const CHANGE_DEFINED_AREA_IN_DELETE = 'CHANGE_DEFINED_AREA_IN_DELETE';
export function changeDefinedAreaInDelete(definedArea) {
  return {
    type: CHANGE_DEFINED_AREA_IN_DELETE,
    definedArea,
  };
}

export const CLEAR_DEFINED_AREA_IN_DELETE = 'CLEAR_DEFINED_AREA_IN_DELETE';
export function clearDefinedAreaInDelete() {
  return {
    type: CLEAR_DEFINED_AREA_IN_DELETE,
  };
}

export const fetchNoFlightAreas = createAsyncThunk(
  'FETCH_NO_FLIGHT_AREAS',
  async ({ firName, filter, from, to }) => queryNoFlightAreas(firName, filter, from, to),
);

export const fetchUserDefinedAreas = createAsyncThunk(
  'FETCH_USER_DEFINED_AREAS',
  async ({ firName, filter, from, to }) => queryUserDefinedAreas(firName, filter, from, to),
);

export const createDefinedArea = createAsyncThunk(
  'CREATE_DEFINED_AREA',
  async ({ firName, newArea }) => addDefinedArea(firName, newArea),
);

export const deleteDefinedArea = createAsyncThunk(
  'DELETE_DEFINED_AREA',
  async ({ firName, id }) => deleteDefinedAreas(firName, id),
);

export const editDefinedArea = createAsyncThunk(
  'EDIT_DEFINED_AREA',
  async ({ firName, id, editedArea }) => patchDefinedAreas(firName, id, editedArea),
);
