/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { addFavoriteTfv, fetchAccData, fetchFavoriteTfvs, fetchSectorContour, fetchHotspotContour, removeFavoriteTfv } from './actions';

const initialState = {
  acc: undefined,
  sector: undefined,
  hotspot: undefined,
  favoriteTfvs: [],
};

const handleError = (state, { payload }) => {
  state.error = payload;
};

const tfvSlice = createSlice({
  name: 'tfv',
  initialState,
  reducers: {
    clearSectorContour: (state) => {
      state.sector = undefined;
    },
    clearHotspotContour: (state) => {
      state.hotspot = undefined;
    },
  },
  extraReducers: {
    [fetchAccData.fulfilled]: (state, { payload }) => {
      state.acc = payload;
    },
    [fetchAccData.rejected]: handleError,
    [fetchSectorContour.fulfilled]: (state, { payload }) => {
      state.sector = payload;
    },
    [fetchSectorContour.rejected]: handleError,
    [fetchHotspotContour.fulfilled]: (state, { payload }) => {
      state.hotspot = payload;
    },
    [fetchHotspotContour.rejected]: handleError,
    [fetchFavoriteTfvs.fulfilled]: (state, { payload }) => {
      state.favoriteTfvs = payload;
    },
    [fetchFavoriteTfvs.rejected]: handleError,
    [addFavoriteTfv.fulfilled]: (state, { payload }) => {
      if (!state.favoriteTfvs) state.favoriteTfvs = [];
      state.favoriteTfvs = [payload, ...state.favoriteTfvs];
    },
    [addFavoriteTfv.rejected]: handleError,
    [removeFavoriteTfv.fulfilled]: (state, { payload }) => {
      state.favoriteTfvs = state.favoriteTfvs.filter(tfv => tfv !== payload);
    },
    [removeFavoriteTfv.rejected]: handleError,
  },
});

export const { clearSectorContour, clearHotspotContour } = tfvSlice.actions;
export default tfvSlice.reducer;
