import callApi from '../api/services';
import { XuningPaths } from '../../constants';

export const queryFlowData = firName => callApi(
  `${XuningPaths.FLOW}/${firName}`,
  { basePath: window.env.XUNING_API_BASE_URL },
);

export const createFlowData = (firName, flow) => callApi(
  `${XuningPaths.FLOW}/${firName}`,
  { basePath: window.env.XUNING_API_BASE_URL, method: 'POST', body: flow },
);

export const putFlowData = (id, firName, flow) => callApi(
  `${XuningPaths.FLOW}/${firName}/${id}`,
  { basePath: window.env.XUNING_API_BASE_URL, method: 'PUT', body: flow },
);

export const deleteFlowData = (id, firName) => callApi(
  `${XuningPaths.FLOW}/${firName}/${id}`,
  { basePath: window.env.XUNING_API_BASE_URL, method: 'DELETE' },
);
