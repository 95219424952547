/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchUserSettings } from './actions';

const initialState = {};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  extraReducers: {
    [fetchUserSettings.fulfilled](state, { payload }) {
      state.show_lr_tips = payload['show-lr-tips'];
      state.show_co2 = payload['show-co2'];
    },
    [fetchUserSettings.rejected](state, { payload }) {
      state.error = payload;
    },

  },
});

export default settingsSlice.reducer;
