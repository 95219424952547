import callApi from '../api/services';
import { NMPaths } from '../../constants';

const getUpdatePlanFromNM = (profileName, index) => callApi(`${NMPaths.NM_UPDATE}`, { basePath: window.env.FLOW_API_BASE_URL,
  method: 'POST',
  body: {
    profile_name: profileName,
    days_delta: index,
  } });

export default getUpdatePlanFromNM;
