/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchUserAirports, fetchFlightScheduledTime } from './actions';

const initialState = {
  defaultAirport: undefined,
  defaultRoute: undefined,
  airports: [],
  currentMode: 'departure',
  active: false,
  flightSeq: { gufi: undefined, callsign: undefined, scheduledTime: undefined, adep: undefined, ades: undefined },
};

const approachSlice = createSlice({
  name: 'approach',
  initialState,
  reducers: {
    setMode: (state, { payload }) => {
      state.currentMode = payload;
    },
  },
  extraReducers: {
    [fetchUserAirports.fulfilled]: (state, { payload }) => {
      const { defaultAirport, defaultRoute, airports } = payload;
      state.defaultAirport = defaultAirport;
      state.defaultRoute = defaultRoute;

      if (airports) {
        state.airports = airports.map(({ code, writeArrivalSequence, writeDepartureSequence }) => ({
          code,
          writeArrivalSequence,
          writeDepartureSequence,
        }));
      } else {
        state.airports = [];
      }

      state.active = true;
    },
    [fetchUserAirports.rejected]: (state) => {
      state.active = false;
      state.defaultAirport = undefined;
      state.defaultRoute = undefined;
      state.airports = [];
    },
    [fetchFlightScheduledTime.fulfilled]: (state, { payload }) => {
      const { gufi, callsign, scheduledTime, adep, ades } = payload;
      state.flightSeq.gufi = gufi;
      state.flightSeq.callsign = callsign;
      state.flightSeq.scheduledTime = scheduledTime;
      state.flightSeq.adep = adep;
      state.flightSeq.ades = ades;
    },
    [fetchFlightScheduledTime.rejected]: (state) => {
      state.flightSeq.gufi = undefined;
      state.flightSeq.callsign = undefined;
      state.flightSeq.scheduledTime = undefined;
      state.flightSeq.adep = undefined;
      state.flightSeq.ades = undefined;
    },
  },
});

export const { setMode } = approachSlice.actions;
export default approachSlice.reducer;
