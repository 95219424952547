import { createAsyncThunk } from '@reduxjs/toolkit';
import { queryBackendLicenses, queryHmiLicenses } from './services';

export const fetchHmiLicenses = createAsyncThunk(
  'FETCH_HMI_LICENSES',
  async () => queryHmiLicenses(),
);

export const fetchBackendLicenses = createAsyncThunk(
  'FETCH_BACKEND_LICENSES',
  async () => queryBackendLicenses(),
);
