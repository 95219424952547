import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useTheme } from '@atfm/atfm-material-ui';

export default () => {
  const containerStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const theme = useTheme();
  const { grey4: loaderColor } = theme.palette.common;
  const { overline: textStyles } = theme.typography;

  return (
    <div style={containerStyles}>
      <div data-testid="loading-indicator">
        <TailSpin
          ariaLabel="loading-indicator"
          height={20}
          width={20}
          color={loaderColor}
          secondaryColor={loaderColor}
        />
      </div>
      <p style={textStyles}>Loading...</p>
    </div>
  );
};
