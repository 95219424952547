import { createAsyncThunk } from '@reduxjs/toolkit';
import queryFlightsListData, { deleteFlightTag, patchFlightPriority, putCustomTag, queryCustomTags, queryFlight } from './services';
import { fetchFlightsPositionsData } from '../flightData/actions';

export const LOADING_FLIGHTS_LIST_DATA = 'LOADING_FLIGHTS_LIST_DATA';
export function loadingFlightsListData() {
  return {
    type: LOADING_FLIGHTS_LIST_DATA,
  };
}

export const LOADING_FLIGHTS_LIST_DATA_SUCCESS = 'LOADING_FLIGHTS_LIST_DATA_SUCCESS';
export function loadingFlightsListDataSuccess(flightsListData) {
  return {
    type: LOADING_FLIGHTS_LIST_DATA_SUCCESS,
    flightsListData,
  };
}

export const LOADING_FLIGHTS_LIST_DATA_FAILURE = 'LOADING_FLIGHTS_LIST_DATA_FAILURE';
export function loadingFlightsListDataFailure(error) {
  return {
    type: LOADING_FLIGHTS_LIST_DATA_FAILURE,
    error,
  };
}

export const FILTERED_FLIGHTS_LIST = 'FILTERED_FLIGHTS_LIST';
export function updateFilteredFlights(filters) {
  return {
    type: FILTERED_FLIGHTS_LIST,
    filters,
  };
}

export const CLEAR_FILTERED_FLIGHTS_LIST = 'CLEAR_FILTERED_FLIGHTS_LIST';
export function clearFilteredFlights() {
  return {
    type: CLEAR_FILTERED_FLIGHTS_LIST,
  };
}

export const CLEAR_FILTERED_FIELD_FLIGHTS_LIST = 'CLEAR_FILTERED_FIELD_FLIGHTS_LIST';
export function clearFilteredFieldFlights(dataKey) {
  return {
    type: CLEAR_FILTERED_FIELD_FLIGHTS_LIST,
    dataKey,
  };
}

export const BRUSHED_FLIGHTS = 'BRUSHED_FLIGHTS';
export function updateBrushedFlights(brushSelection, tfvId, from, to) {
  return {
    type: BRUSHED_FLIGHTS,
    tfvId,
    brushSelection,
    from,
    to,
  };
}

export const CLEAR_BRUSH = 'CLEAR_BRUSH';
export function clearBrushSelection() {
  return {
    type: CLEAR_BRUSH,
  };
}

export const FILTERED_HOTSPOT = 'FILTERED_HOTSPOT';
export function updateHotspotFilter(hotspotFilter, hotspot) {
  return {
    type: FILTERED_HOTSPOT,
    hotspotFilter,
    hotspot,
  };
}

export const CLEAR_FILTERED_HOTSPOT = 'CLEAR_FILTERED_HOTSPOT';
export function clearHotspotFilter() {
  return {
    type: CLEAR_FILTERED_HOTSPOT,
  };
}

/* Async Action Creators */
export const fetchFlightsListData = (tfvName, forecastPeriod, atTime = undefined, currentTime = undefined, sessionId = undefined, waitForJob = false,
  horizon = undefined) => (dispatch) => {
  dispatch(loadingFlightsListData());
  return queryFlightsListData(tfvName, currentTime, sessionId, forecastPeriod, waitForJob, horizon).then((flights) => {
    dispatch(loadingFlightsListDataSuccess(flights));
    dispatch(fetchFlightsPositionsData(flights, atTime, sessionId));
  }).catch((error) => {
    dispatch(loadingFlightsListDataFailure(error));
  });
};

export const TOGGLE_FLIGHT_PRIORITY = 'TOGGLE_FLIGHT_PRIORITY';
export function toogleFlightPriority(gufi, priority) {
  return {
    type: TOGGLE_FLIGHT_PRIORITY,
    gufi,
    priority,
  };
}

export const TOGGLE_FLIGHT_PRIORITY_SUCCESS = 'TOGGLE_FLIGHT_PRIORITY_SUCCESS';
export function toogleFlightPrioritySuccess() {
  return {
    type: TOGGLE_FLIGHT_PRIORITY_SUCCESS,
  };
}

export const TOGGLE_FLIGHT_PRIORITY_FAILURE = 'TOGGLE_FLIGHT_PRIORITY_FAILURE';
export function toogleFlightPriorityFailure(error) {
  return {
    type: TOGGLE_FLIGHT_PRIORITY_FAILURE,
    error,
  };
}

/* Async Action Creators */
export const updateFlightPriority = (gufi, priority) => (dispatch) => {
  dispatch(toogleFlightPriority(gufi, priority));
  return patchFlightPriority(gufi, priority).then(() => {
    dispatch(toogleFlightPrioritySuccess());
  }).catch((error) => {
    dispatch(toogleFlightPriorityFailure(error));
  });
};

export const LOADING_CUSTOM_TAGS = 'LOADING_CUSTOM_TAGS';
export function loadingCustomTags() {
  return {
    type: LOADING_CUSTOM_TAGS,
  };
}

export const LOADING_CUSTOM_TAGS_SUCCESS = 'LOADING_CUSTOM_TAGS_SUCCESS';
export function loadingCustomTagsSuccess(tags) {
  return {
    type: LOADING_CUSTOM_TAGS_SUCCESS,
    payload: {
      tags,
    },
  };
}

export const LOADING_CUSTOM_TAGS_FAILURE = 'LOADING_CUSTOM_TAGS_FAILURE';
export function loadingCustomTagsFailure(error) {
  return {
    type: LOADING_CUSTOM_TAGS_FAILURE,
    error,
  };
}

export const fetchCustomTags = () => async (dispatch) => {
  dispatch(loadingCustomTags());
  try {
    const tags = await queryCustomTags();
    dispatch(loadingCustomTagsSuccess(tags));
  } catch (error) {
    dispatch(loadingCustomTagsFailure(error));
  }
};

export const setCustomTag = createAsyncThunk(
  'SET_CUSTOM_TAG',
  async ({ gufi, tag }) => putCustomTag(gufi, tag),
);

export const fetchFlight = createAsyncThunk(
  'LOAD_FLIGHT',
  async gufi => queryFlight(gufi),
);

export const removeCustomTag = createAsyncThunk(
  'DELETE_CUSTOM_TAG',
  async ({ gufi, tag }) => deleteFlightTag(gufi, tag),
);
