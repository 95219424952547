import { createAsyncThunk } from '@reduxjs/toolkit';
import { Time } from '../../constants';
import {
  fetchGetWhatif,
  fetchDeleteWhatif,
  fetchPutWhatif,
  fetchAddMeasure,
  fetchDeleteMeasure,
  fetchUpdateMeasure,
  fetchProposeStam,
  fetchSimulationsList,
  fetchComputeNewTrajectory,
} from './services';

import { updateHotspotsAfterWhatIfCreation, updateHotspotsAfterWhatIfDeletion } from '../hotspots/actions';

export const addMeasure = createAsyncThunk(
  'ADD_MEASURE',
  async ({ sessionId, measure, measureType }) => fetchAddMeasure(sessionId, measure, measureType.toLowerCase()),
);

export const deleteMeasure = createAsyncThunk(
  'DELETE_MEASURE',
  async ({ measureId, measureType }) => fetchDeleteMeasure(measureId, measureType),
);

export const updateMeasure = createAsyncThunk(
  'UPDATE_MEASURE',
  // eslint-disable-next-line no-unused-vars
  async ({ sessionId, updatedFields, measureId, measureType }) => fetchUpdateMeasure(updatedFields, measureId, measureType),
);

export const getWhatif = createAsyncThunk(
  'GET_WHATIF',
  async ({ sessionId }) => fetchGetWhatif(sessionId),
);

export const deleteWhatif = createAsyncThunk(
  'DELETE_WHATIF',
  async (sessionId, thunkAPI) => {
    await fetchDeleteWhatif(sessionId);
    thunkAPI.dispatch(updateHotspotsAfterWhatIfDeletion(sessionId));
    return { sessionId };
  },
);

export const createWhatif = createAsyncThunk(
  'CREATE_WHATIF',
  async ({ sessionId, createdAt, eventType }, thunkAPI) => {
    const response = await fetchPutWhatif(sessionId, createdAt, eventType);
    thunkAPI.dispatch(updateHotspotsAfterWhatIfCreation(response));
    return response;
  },
);

export const createCopyWhatif = createAsyncThunk(
  'CREATE_COPY_WHATIF',
  async ({ sessionId, measure }, thunkAPI) => {
    const response = await fetchPutWhatif(sessionId, null);

    const copyMeasureId = response.measures.find(m => m.copyOf === measure.id).id;

    thunkAPI.dispatch(updateMeasure({
      sessionId,
      updatedFields: { ...measure, active: !measure.active },
      measureId: copyMeasureId,
      measureType: measure.type }));

    thunkAPI.dispatch(updateHotspotsAfterWhatIfCreation(response));

    return response;
  },
);

export const proposeStam = createAsyncThunk(
  'PROPOSE_STAM',
  async sessionId => fetchProposeStam(sessionId),
);

export const getFullDaySimulationsList = createAsyncThunk(
  'GET_FULL_DAY_SIMULATIONS_LIST',
  async (currentTime) => {
    const from = currentTime - 12 * Time.HOUR_IN_MILISECONDS;
    const to = currentTime + 24 * Time.HOUR_IN_MILISECONDS;

    return fetchSimulationsList(from, to);
  },
);

export const getComputeNewTrajectory = createAsyncThunk(
  'GET_COMPUTE_NEW_TRAJECTORY',
  async ({ gufi, callsign, trajectory, type }) => fetchComputeNewTrajectory(gufi, callsign, trajectory, type),
);
