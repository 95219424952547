import callApi from '../api/services';
import { PositionsPaths } from '../../constants';

const queryFlightsData = (gufis, atTime, sessionId) => {
  let separator = '?';
  let query = '';
  if (atTime) {
    query = `?at=${atTime}`;
    separator = '&';
  }
  query = sessionId ? `${query}${separator}sessionId=${sessionId}` : query;
  return callApi(`${PositionsPaths.POSITIONS}${query}`, { basePath: window.env.POSITIONS_API_BASE_URL, method: 'POST', body: gufis });
};

export default queryFlightsData;
