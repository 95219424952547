import {
  UPDATE_FORECAST_HOTSPOT,
  UPDATE_FORECAST_FAB_CE,
  UPDATE_FORECAST_TIMELINE,
} from './actions';
import { ForecastSources } from '../../constants';

const initial = { forecastTime: undefined, forecastSource: undefined };
export default (state = initial, action = {}) => {
  switch (action.type) {
    case UPDATE_FORECAST_HOTSPOT: {
      const { hotspotStartTime } = action;
      const forecastSource = hotspotStartTime ? ForecastSources.HOTSPOT : undefined;
      return { ...state, forecastTime: hotspotStartTime, forecastSource };
    }
    case UPDATE_FORECAST_FAB_CE: {
      const { fabCeStartTime } = action;
      const forecastSource = fabCeStartTime ? ForecastSources.FABCE : undefined;
      return { ...state, forecastTime: fabCeStartTime, forecastSource };
    }
    case UPDATE_FORECAST_TIMELINE: {
      const { timelineForecastTime } = action;
      const forecastSource = timelineForecastTime ? ForecastSources.TIMELINE : undefined;
      return { ...state, forecastTime: timelineForecastTime, forecastSource };
    }
    default: {
      return state;
    }
  }
};
