import {
  REPLAY_SESSION_START,
  REPLAY_SESSION_END,
} from './actions';

const initial = {
  isReplaySessionActive: false,
  inputSettings: {},
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case REPLAY_SESSION_START: {
      const { date, time, duration } = action.payload;
      return {
        ...state,
        isReplaySessionActive: true,
        inputSettings: {
          date,
          time,
          duration,
        },
      };
    }
    case REPLAY_SESSION_END: {
      return {
        ...state,
        isReplaySessionActive: false,
      };
    }
    default: {
      return state;
    }
  }
};
