import { queryUserInfo, queryForecastPeriod } from './services';

export const LOADING_USER_INFO = 'LOADING_USER_AREA';
export function loadingUserInfo() {
  return {
    type: LOADING_USER_INFO,
  };
}

export const LOADING_USER_INFO_SUCCESS = 'LOADING_USER_AREA_SUCCESS';
export function loadingUserInfoSuccess({
  uid, area, tfv, acc, secto, neighbours, occStep, entryStep, occDuration, entryDuration,
}) {
  return {
    type: LOADING_USER_INFO_SUCCESS,
    uid,
    area,
    tfv,
    acc,
    secto,
    neighbours,
    occStep,
    entryStep,
    occDuration,
    entryDuration,
  };
}

export const LOADING_USER_INFO_FAILURE = 'LOADING_USER_AREA_FAILURE';
export function loadingUserInfoFailure(error) {
  return {
    type: LOADING_USER_INFO_FAILURE,
    error,
  };
}

export const fetchUserInfo = () => (dispatch) => {
  dispatch(loadingUserInfo());
  return queryUserInfo()
    .then(data => dispatch(loadingUserInfoSuccess(data)))
    .catch(error => dispatch(loadingUserInfoFailure(error)));
};

export const LOADING_USER_FORECAST_PERIOD = 'LOADING_USER_FORECAST_PERIOD';
export function loadingUserForecastPeriod() {
  return {
    type: LOADING_USER_FORECAST_PERIOD,
  };
}

export const LOADING_USER_FORECAST_PERIOD_SUCCESS = 'LOADING_USER_FORECAST_PERIOD_SUCCESS';
export function loadingUserForecastPeriodSuccess({
  forecast_period: forecastPeriod,
  forecast_period_charts: forecastPeriodCharts,
}) {
  return {
    type: LOADING_USER_FORECAST_PERIOD_SUCCESS,
    forecastPeriod,
    forecastPeriodCharts,
  };
}

export const LOADING_USER_FORECAST_PERIOD_FAILURE = 'LOADING_USER_FORECAST_PERIOD_FAILURE';
export function loadingUserForecastPeriodFailure(error) {
  return {
    type: LOADING_USER_FORECAST_PERIOD_FAILURE,
    error,
  };
}

export const fetchUserForecastPeriod = () => (dispatch) => {
  dispatch(loadingUserForecastPeriod());
  return queryForecastPeriod()
    .then(data => dispatch(loadingUserForecastPeriodSuccess(data)))
    .catch(error => dispatch(loadingUserForecastPeriodFailure(error)));
};
