/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
import moment from 'moment';
import { HotspotsTypes, Severities, HotspotColor, HotspotFontColor, TrafficLoadsTimeWindow } from '../constants';

export default class HotspotsUtil {
  static sortByStart(a, b) {
    if (a.start < b.start) return -1;
    if (a.start > b.start) return 1;
    return 0;
  }

  static sortHotspotsByStartTime(hotspots) {
    if (hotspots && hotspots.length > 0) {
      return [...hotspots].sort(HotspotsUtil.sortByStart);
    }

    return [];
  }

  static selectHotspotsForTimeline(hotspots, severity, hotspotRows) {
    const filteredSevereHotspots =
      hotspots.filter(hotspot => hotspot.severity.toUpperCase() === severity);

    HotspotsUtil.groupHotspotsForTimeline(filteredSevereHotspots, hotspotRows);
  }

  static groupHotspotsForTimeline(hotspots, hotspotRows) {
    const maxRows = 6;
    const sortedHotspots = HotspotsUtil.sortHotspotsByStartTime(hotspots);

    for (let i = 0; i < sortedHotspots.length; i++) {
      const sortedHotspot = sortedHotspots[i];

      for (let r = 0; r < maxRows; r++) {
        if (hotspotRows.length === r) {
          // need one more row
          hotspotRows.push([]);
        }
        if (hotspotRows[r].length < 1) {
          hotspotRows[r].push(sortedHotspot);
          break;
        }

        let isRejected = false;

        for (let j = 0; j < hotspotRows[r].length; j++) {
          const existingHotspot = hotspotRows[r][j];

          if (existingHotspot.start < sortedHotspot.start) {
            if (existingHotspot.end <= sortedHotspot.start ||
                (existingHotspot.tfvId && existingHotspot.tfvId === sortedHotspot.tfvId)) {
              continue;
            }

            isRejected = true;
            break;
          }

          if (existingHotspot.start >= sortedHotspot.start) {
            if (existingHotspot.start >= sortedHotspot.end ||
                (existingHotspot.tfvId && existingHotspot.tfvId === sortedHotspot.tfvId)) {
              continue;
            }

            isRejected = true;
            break;
          }
        }

        if (!isRejected || r === (maxRows - 1)) {
          // allow overlaps on last line
          hotspotRows[r].push(sortedHotspot);
          break;
        }
      }
    }
  }

  static selectHotspotsToDisplay(hotspots, hotspotsType, currentTime, minRows) {
    // at least 5 rows reserved
    const reservedRows = minRows || 5;
    const hotspotRows = [];
    for (let i = 0; i < reservedRows; i++) {
      hotspotRows.push([]);
    }

    // RDT types, filtered by severity
    if (hotspotsType === HotspotsTypes.WEATHER) {
      // Round 1: SEVERE hotspots
      HotspotsUtil.selectHotspotsForTimeline(hotspots, 'SEVERE', hotspotRows);

      // Round 2: MODERATE hotspots
      HotspotsUtil.selectHotspotsForTimeline(hotspots, 'MODERATE', hotspotRows);
    } else {
      // Group hotspots
      HotspotsUtil.groupHotspotsForTimeline(hotspots, hotspotRows);
    }

    return HotspotsUtil.format(hotspotRows, hotspotsType, currentTime);
  }

  static getColor(currentTime, end, severity) {
    if (Severities.MODERATE === severity) {
      if (currentTime && end < currentTime) {
        return HotspotColor.MODERATE_IN_PAST;
      }
      return HotspotColor.MODERATE;
    }

    if (Severities.SEVERE === severity) {
      if (currentTime && end < currentTime) {
        return HotspotColor.SEVERE_IN_PAST;
      }
      return HotspotColor.SEVERE;
    }

    return (currentTime ? `${severity}${end < currentTime ? '_old' : ''}` : severity);
  }

  static getFontColor(severity) {
    return HotspotFontColor[severity];
  }

  static formatHotspot(hotspot, currentTime) {
    // If the start of the sector-plan is before the display, trim
    const startTime = currentTime - TrafficLoadsTimeWindow.BEFORE;
    let minStart = hotspot.start;
    if (minStart < startTime) {
      minStart = startTime;
    }

    const newHotspot = {
      hotspotId: hotspot.uuid,
      start: hotspot.start,
      end: hotspot.end,
      starting_time: minStart,
      ending_time: hotspot.end,
      flights: hotspot.gufis.length,
      severity: hotspot.severity,
      timelineColor: this.getColor(currentTime, hotspot.end, hotspot.severity),
      color: this.getColor(false, hotspot.end, hotspot.severity),
      textColor: this.getFontColor(hotspot.severity),
      gufis: hotspot.gufis,
      sessionId: hotspot.sessionId,
      type: hotspot.type,
      isDeclared: hotspot.isDeclared,
      status: hotspot.status,
      maxFL: hotspot.maxFL,
      minFL: hotspot.minFL,
      value: hotspot.gufis.length,
      areaId: hotspot.areaId,
    };

    if (hotspot.type === HotspotsTypes.RDT || hotspot.type === HotspotsTypes.CAT) {
      newHotspot.topFl = hotspot.top;
      newHotspot.weatherIds = hotspot.weatherIds;
    } else {
      newHotspot.sector = hotspot.tfvId;
      newHotspot.peak = hotspot.peak;
      newHotspot.sustain = hotspot.sustain;
    }

    return newHotspot;
  }

  static format(hotspotRows, hotspotsType, currentT) {
    let currentTime = moment().valueOf();
    if (currentT) {
      currentTime = currentT;
    }

    const formatedRows = hotspotRows.map(row => ({
      class: hotspotsType, times: row.map(hotspot => HotspotsUtil.formatHotspot(hotspot, currentTime)),
    }));

    return formatedRows;
  }
}
