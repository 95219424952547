import callApi from '../api/services';
import { TfvPaths, XuningPaths } from '../../constants';

export const queryTfvData = tfvName =>
  callApi(
    `${TfvPaths.TFV}${tfvName}${TfvPaths.REFLOCATION}`,
    { basePath: window.env.TFV_API_BASE_URL, headers: { Accept: 'application/geo+json' } },
  );

export const queryTfvByPrefix = tfvName =>
  callApi(`?idPrefix=${tfvName}`, { basePath: window.env.TFV_API_BASE_URL });

export const queryTfvFullData = tfvName =>
  callApi(`${TfvPaths.TFV}${tfvName}`, { basePath: window.env.TFV_API_BASE_URL });

export const queryFavoriteTfvs = () => callApi(`${XuningPaths.FAVORITE_TFVS}`, { basePath: window.env.XUNING_API_BASE_URL });

export const putFavoriteTfv = tfvId => callApi(`${XuningPaths.FAVORITE_TFVS}/${tfvId}`, { basePath: window.env.XUNING_API_BASE_URL, method: 'PUT' });

export const deleteFavoriteTfv = tfvId => callApi(`${XuningPaths.FAVORITE_TFVS}/${tfvId}`, { basePath: window.env.XUNING_API_BASE_URL, method: 'DELETE' });
