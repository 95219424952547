import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';

import { useAuthContext } from '../../containers/Context/AuthContext';

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;

  const [isReady, setIsReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { initialization, login } = useAuthContext();

  useEffect(() => {
    const awaitInitialization = async () => {
      try {
        setIsAuthenticated(await initialization);
      } finally {
        setIsReady(true);
      }
    };

    awaitInitialization();
  }, []);

  if (!isReady) {
    return null;
  }

  if (!isAuthenticated) {
    login();
    return null;
  }

  return <Route {...rest} render={() => <Component {...props} />} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element, // if component is class component
    PropTypes.elementType, // if component is stateless functional component
  ]).isRequired,
};

export default PrivateRoute;
