export const REPLAY_SESSION_START = 'REPLAY_SESSION_START';
export function startReplaySession({ date, time, duration }) {
  return {
    type: REPLAY_SESSION_START,
    payload: {
      date,
      time,
      duration,
    },
  };
}

export const REPLAY_SESSION_END = 'REPLAY_SESSION_END';
export function endReplaySession() {
  return {
    type: REPLAY_SESSION_END,
  };
}
