import React from 'react';
import PropTypes from 'prop-types';

import './InsertNewConfig.css';

const insertNewConfig = ({ listId, entryId, insertConfig }) => (
  <div className="insertConfiguration">
    <div className="line" />
    <button className="insertConfigurationButton" onClick={() => insertConfig(listId, entryId)} type="button">
      <span>Insert Configuration</span>
    </button>
  </div>
);

insertNewConfig.propTypes = {
  listId: PropTypes.number.isRequired,
  entryId: PropTypes.number.isRequired,
  insertConfig: PropTypes.func.isRequired,
};

export default insertNewConfig;
