import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserAirports, getFlightScheduledTime } from './services';

export const fetchUserAirports = createAsyncThunk(
  'FETCH_USER_AIRPORTS',
  async () => getUserAirports(),
);

export const fetchFlightScheduledTime = createAsyncThunk(
  'FETCH_FLIGHT_SCHEDULED_TIME',
  async ({ airport, gufi, flow }) => getFlightScheduledTime(airport, gufi, flow),
);
