import { Time } from '@atfm/utils';
import queryEntryData from './services';

export const LOADING_ENTRY_DATA = 'LOADING_ENTRY_DATA';
export function loadingEntryData(requestData) {
  return {
    type: LOADING_ENTRY_DATA,
    requestData,
  };
}

export const LOADING_SECTO_PLAN_ENTRY_DATA = 'LOADING_SECTO_PLAN_ENTRY_DATA';
export function loadingSectoPlanEntryData(requestData) {
  return {
    type: LOADING_SECTO_PLAN_ENTRY_DATA,
    requestData,
  };
}

export const LOADING_ENTRY_DATA_SUCCESS = 'LOADING_ENTRY_DATA_SUCCESS';
export function loadingEntryDataSuccess(entryData, requestData) {
  return {
    type: LOADING_ENTRY_DATA_SUCCESS,
    entryData,
    requestData,
  };
}

export const LOADING_SECTO_PLAN_ENTRY_DATA_SUCCESS = 'LOADING_SECTO_PLAN_ENTRY_DATA_SUCCESS';
export function loadingSectoPlanEntryDataSuccess(sectoPlanEntryData, requestData) {
  return {
    type: LOADING_SECTO_PLAN_ENTRY_DATA_SUCCESS,
    sectoPlanEntryData,
    requestData,
  };
}

export const LOADING_ENTRY_DATA_FAILURE = 'LOADING_ENTRY_DATA_FAILURE';
export function loadingEntryDataFailure(error) {
  return {
    type: LOADING_ENTRY_DATA_FAILURE,
    error,
  };
}

export const LOADING_SECTO_PLAN_ENTRY_DATA_FAILURE = 'LOADING_SECTO_PLAN_ENTRY_DATA_FAILURE';
export function loadingSectoPlanEntryDataFailure(error) {
  return {
    type: LOADING_SECTO_PLAN_ENTRY_DATA_FAILURE,
    error,
  };
}

/* Async Action Creators */
export const fetchEntryData = (tfvs, start, end, step, duration, sessionId, waitForJob, invalidate, horizon, isComparison, isHotspot) => (dispatch) => {
  const miliStep = step * 60000;
  const miliDuration = duration * 60000;
  const requestData = {
    tfvs, start, end, miliStep, miliDuration, sessionId, isComparison, isHotspot,
  };
  dispatch(loadingEntryData(requestData));
  return queryEntryData(tfvs, start, end, miliStep, miliDuration, sessionId, waitForJob, invalidate, horizon)
    .then(entry => dispatch(loadingEntryDataSuccess(entry, requestData)))
    .catch(err => dispatch(loadingEntryDataFailure(err)));
};

export const fetchSectoPlanEntryData = (tfvs, start, end, step, duration, sessionId, waitForJob, invalidate, horizon) => (dispatch) => {
  const miliStep = step * Time.MINUTE_IN_MILISECONDS;
  const miliDuration = duration * Time.MINUTE_IN_MILISECONDS;
  const requestData = {
    tfvs, start, end, miliStep, miliDuration, sessionId,
  };
  dispatch(loadingSectoPlanEntryData(requestData));

  return queryEntryData(tfvs, start, end, miliStep, miliDuration, sessionId, waitForJob, invalidate, horizon)
    .then((sectoEntry) => {
      dispatch(loadingSectoPlanEntryDataSuccess(sectoEntry, requestData));
    }).catch((err) => {
      dispatch(loadingSectoPlanEntryDataFailure(err));
    });
};
