import callApi from '../api/services';
import { ReportsPaths } from '../../constants';

export const queryReports = (firName, from, to) =>
  callApi(`${ReportsPaths.REPORTS_FIR}/${firName}?from=${from}&to=${to}`, { basePath: window.env.REPORTS_API_BASE_URL });

export const createReport = (firName, from, to, duration, step) =>
  callApi(
    `${ReportsPaths.WIZARD}?fir=${firName}&start=${from}&end=${to}&duration=${duration}&step=${step}`,
    { basePath: window.env.REPORTS_API_BASE_URL },
  );

export const saveReport = (id, editedReport) => {
  if (id) {
    return callApi(`${ReportsPaths.REPORTS_HEADER}/${id}`, { basePath: window.env.REPORTS_API_BASE_URL, method: 'PUT', body: editedReport });
  }
  return callApi(`${ReportsPaths.REPORTS_HEADER}`, { basePath: window.env.REPORTS_API_BASE_URL, method: 'POST', body: editedReport });
};

export const refreshReport = (duration, step, currReport) =>
  callApi(`${ReportsPaths.WIZARD}?duration=${duration}&step=${step}`, { basePath: window.env.REPORTS_API_BASE_URL, method: 'POST', body: currReport });
