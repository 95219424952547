import callApi from '../api/services';
import { DefinedAreaPaths, DefinedAreasDisplayStatus } from '../../constants';

const buildParams = (filter, from, to) => {
  if (filter === DefinedAreasDisplayStatus.NONE) {
    throw Error('If area filter is NONE no request should actually be made');
  }
  if (filter === DefinedAreasDisplayStatus.ALL) {
    return '';
  }
  return `&from=${from}&to=${to}${filter === DefinedAreasDisplayStatus.INACTIVE ? '&inactive=true' : ''}`;
};

export const queryNoFlightAreas = (firName, filter, from, to) =>
  callApi(`${DefinedAreaPaths.DEF_AREAS}/${firName}${DefinedAreaPaths.NFA}?format=geojson${buildParams(filter, from, to)}`,
    { basePath: window.env.DEF_AREAS_API_BASE_URL });

export const queryUserDefinedAreas = (firName, filter, from, to) =>
  callApi(`${DefinedAreaPaths.DEF_AREAS}/${firName}${DefinedAreaPaths.UDA}?format=geojson${buildParams(filter, from, to)}`,
    { basePath: window.env.DEF_AREAS_API_BASE_URL });

export const addDefinedArea = (firName, newArea) =>
  callApi(`${DefinedAreaPaths.DEF_AREAS}/${firName}`, { basePath: window.env.DEF_AREAS_API_BASE_URL, method: 'PUT', body: newArea });

export const deleteDefinedAreas = (firName, id) =>
  callApi(`${DefinedAreaPaths.DEF_AREAS}/${firName}/${id}`, { basePath: window.env.DEF_AREAS_API_BASE_URL, method: 'DELETE' });

export const patchDefinedAreas = (firName, id, editedArea) =>
  callApi(`${DefinedAreaPaths.DEF_AREAS}/${firName}/${id}`, { basePath: window.env.DEF_AREAS_API_BASE_URL, method: 'PATCH', body: editedArea });
