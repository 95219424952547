import React, { useRef, useEffect, useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const TooltipContext = createContext();

const usePrevious = (data) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = data;
  }, [data]);
  return ref.current;
};

export const useTooltipDefaultContext = () => {
  const [tooltipData, setTooltipData] = useState(undefined);
  const prevTooltipData = usePrevious(tooltipData);

  return {
    tooltipData,
    setTooltipData,
    prevTooltipData,
  };
};

export const TooltipProvider = ({ children }) => {
  const context = useTooltipDefaultContext();
  return (
    <TooltipContext.Provider value={context}>
      {children}
    </TooltipContext.Provider>
  );
};

TooltipProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default TooltipContext;
export const useTooltipContext = () => useContext(TooltipContext);
