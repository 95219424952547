import { StorageHelper } from '@atfm/utils';
import {
  END_SIGNIN,
  SIGNOUT_REQUEST,
} from './actions';

export default (state = StorageHelper.getStorageInitialState(), action = {}) => {
  switch (action.type) {
    case END_SIGNIN:
      return state;
    case SIGNOUT_REQUEST:
      if (window.propagateSignout) window.propagateSignout();
      return { ...StorageHelper.getStorageInitialState() };
    default:
      return state;
  }
};
