import { queryReports, createReport, saveReport, refreshReport } from './services';
import { RegularExpressions } from '../../constants';

export const LOADING_REPORT_DATA = 'LOADING_REPORT_DATA';
export function loadingReportData(requestData) {
  return {
    type: LOADING_REPORT_DATA,
    requestData,
  };
}

export const LOADING_REPORT_DATA_SUCCESS = 'LOADING_REPORT_DATA_SUCCESS';
export function loadingReportDataSuccess(reportData, requestData) {
  return {
    type: LOADING_REPORT_DATA_SUCCESS,
    reportData,
    requestData,
  };
}

export const LOADING_REPORT_DATA_FAILURE = 'LOADING_REPORT_DATA_FAILURE';
export function loadingReportDataFailure(error) {
  return {
    type: LOADING_REPORT_DATA_FAILURE,
    error,
  };
}
export const LOADING_REPORT_ARCHIVED_DATA = 'LOADING_REPORT_ARCHIVED_DATA';
export function loadingReportArchivedData() {
  return {
    type: LOADING_REPORT_ARCHIVED_DATA,
  };
}

export const LOADING_REPORT_ARCHIVED_DATA_SUCCESS = 'LOADING_REPORT_ARCHIVED_DATA_SUCCESS';
export function loadingReportArchivedDataSuccess(reportData) {
  return {
    type: LOADING_REPORT_ARCHIVED_DATA_SUCCESS,
    reportData,
  };
}

export const LOADING_REPORT_ARCHIVED_DATA_FAILURE = 'LOADING_REPORT_ARCHIVED_DATA_FAILURE';
export function loadingReportArchivedDataFailure(error) {
  return {
    type: LOADING_REPORT_ARCHIVED_DATA_FAILURE,
    error,
  };
}

export const fetchReports = (firName, from, to) => (dispatch) => {
  const requestData = { firName, from, to };
  dispatch(loadingReportData(requestData));
  return queryReports(firName, from, to)
    .then(data => dispatch(loadingReportDataSuccess(data, requestData)))
    .catch(err => dispatch(loadingReportDataFailure(err)));
};

export const fetchReportsArchived = (firName, from, to) => (dispatch) => {
  // const requestData = { firName, from, to };
  dispatch(loadingReportArchivedData());
  return queryReports(firName, from, to)
    .then(data => dispatch(loadingReportArchivedDataSuccess(data)))
    .catch(err => dispatch(loadingReportArchivedDataFailure(err)));
};

export const CREATING_REPORT_DATA = 'CREATING_REPORT_DATA';
export function creatingReportData(requestData) {
  return {
    type: CREATING_REPORT_DATA,
    requestData,
  };
}

export const CREATING_REPORT_DATA_SUCCESS = 'CREATING_REPORT_DATA_SUCCESS';
export function creatingReportDataSuccess(reportData, requestData) {
  return {
    type: CREATING_REPORT_DATA_SUCCESS,
    reportData,
    requestData,
  };
}

export const CREATING_REPORT_DATA_FAILURE = 'CREATING_REPORT_DATA_FAILURE';
export function creatingReportDataFailure(error) {
  return {
    type: CREATING_REPORT_DATA_FAILURE,
    error,
  };
}

export const creatingReport = (firName, from, to, duration, step) => (dispatch) => {
  const requestData = { firName, from, to };
  dispatch(creatingReportData(requestData));
  return createReport(firName, from, to, duration, step)
    .then(data => dispatch(creatingReportDataSuccess(data, requestData)))
    .catch(error => dispatch(creatingReportDataFailure(error)));
};

export const SAVING_REPORT_DATA = 'SAVING_REPORT_DATA';
export function savingReportData() {
  return {
    type: SAVING_REPORT_DATA,
  };
}

export const SAVING_REPORT_DATA_SUCCESS = 'SAVING_REPORT_DATA_SUCCESS';
export function savingReportDataSuccess(id) {
  return {
    type: SAVING_REPORT_DATA_SUCCESS,
    headerId: id,
  };
}

export const SAVING_REPORT_DATA_FAILURE = 'SAVING_REPORT_DATA_FAILURE';
export function savingReportDataFailure(error) {
  return {
    type: SAVING_REPORT_DATA_FAILURE,
    error,
  };
}

export const savingReport = (id, editedArea) => (dispatch) => {
  dispatch(savingReportData());
  return saveReport(id, editedArea)
    .then((response) => {
      const location = response.headers.get('location');
      const headerId = parseInt(location.match(RegularExpressions.STRING_LAST_DIGITS), 10);
      return dispatch(savingReportDataSuccess(headerId));
    })
    .catch(error => dispatch(savingReportDataFailure(error)));
};

export const REFRESHING_REPORT_DATA = 'REFRESHING_REPORT_DATA';
export function refreshingReportData() {
  return {
    type: REFRESHING_REPORT_DATA,
  };
}

export const REFRESHING_REPORT_DATA_SUCCESS = 'REFRESHING_REPORT_DATA_SUCCESS';
export function refreshingReportDataSuccess(reportData) {
  return {
    type: REFRESHING_REPORT_DATA_SUCCESS,
    reportData,
  };
}

export const REFRESHING_REPORT_DATA_FAILURE = 'REFRESHING_REPORT_DATA_FAILURE';
export function refreshingReportDataFailure(error) {
  return {
    type: REFRESHING_REPORT_DATA_FAILURE,
    error,
  };
}

export const refreshingReport = (duration, step, currReport) => (dispatch) => {
  dispatch(refreshingReportData());
  return refreshReport(duration, step, currReport)
    .then(data => dispatch(refreshingReportDataSuccess(data)))
    .catch(error => dispatch(refreshingReportDataFailure(error)));
};

export const WRITING_REPORT_COMMENT = 'WRITING_REPORT_COMMENT';
export function writingReportComment(sectionType, elementType, id, comment) {
  return {
    type: WRITING_REPORT_COMMENT,
    sectionType,
    elementType,
    id,
    comment,
  };
}
