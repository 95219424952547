import { createSlice } from '@reduxjs/toolkit';
import fetchAirspacesData from './actions';

const initialState = {
  neighbors: [],
  loading: false,
  error: null,
};

const airspacesSlice = createSlice({
  name: 'airspaces',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAirspacesData.pending, state => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(fetchAirspacesData.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        neighbors: action.payload,
      }))
      .addCase(fetchAirspacesData.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }));
  },
});

export default airspacesSlice.reducer;
