export const UPDATE_FORECAST_HOTSPOT = 'UPDATE_FORECAST_HOTSPOT';
export function updateForecastHotspot(hotspotStartTime) {
  return {
    type: UPDATE_FORECAST_HOTSPOT,
    hotspotStartTime,
  };
}

export const UPDATE_FORECAST_FAB_CE = 'UPDATE_FORECAST_FAB_CE';
export function updateForecastFabCe(fabCeStartTime) {
  return {
    type: UPDATE_FORECAST_FAB_CE,
    fabCeStartTime,
  };
}

export const UPDATE_FORECAST_TIMELINE = 'UPDATE_FORECAST_TIMELINE';
export function updateForecastTimeline(timelineForecastTime) {
  return {
    type: UPDATE_FORECAST_TIMELINE,
    timelineForecastTime,
  };
}
