import callApi from '../api/services';
import { FlightsPaths, FlightsConstants, Time, XuningPaths, Horizon } from '../../constants';

const queryFlightsListData = (tfvName, currentTime, sessionId, forecastPeriod, waitForJob, horizon) => {
  let terminator = '?';
  let params = '';

  if (tfvName) {
    params = `/tfv/${tfvName}${params}${terminator}complexity=${FlightsConstants.COMPLEXITY}`;
    terminator = '&'; // for the next param, to use & instead of ?
  }
  if (currentTime) {
    const startTime = currentTime + FlightsConstants.DELTA_FROM;
    let stopTime = currentTime + forecastPeriod * Time.HOUR_IN_MILISECONDS;
    if (horizon !== Horizon.TACTICAL) {
      stopTime += FlightsConstants.DELTA_PRETACT;
    }
    params = `${params}${terminator}from=${startTime}&to=${stopTime}`;
  }
  if (waitForJob) {
    params = `${params}&waitForJob=${waitForJob}`;
  }
  if (sessionId) {
    params = `${params}&sessionId=${sessionId}`;
  }
  if (horizon) {
    params = `${params}&horizon=${horizon}`;
  }

  return callApi(`${FlightsPaths.FLIGHTS}${params}`, { basePath: window.env.FLIGHTS_API_BASE_URL });
};

export const patchFlightPriority = (gufi, priority) => {
  const params = `/${gufi}${FlightsPaths.PRIORITY}`;
  return callApi(
    `${FlightsPaths.FLIGHTS}${params}`,
    { basePath: window.env.FLIGHTS_API_BASE_URL, method: 'PATCH', body: { value: priority } },
  );
};

export const queryCustomTags = () => callApi(`${XuningPaths.CUSTOM_TAGS}`, { basePath: window.env.XUNING_API_BASE_URL });

export const putCustomTag = (gufi, tag) => callApi(
  `${FlightsPaths.FLIGHTS}/${gufi}/tags/${tag}`, { basePath: window.env.FLIGHTS_API_BASE_URL, method: 'PUT' },
);

export const queryFlight = gufi => callApi(
  `${FlightsPaths.FLIGHTS}/${gufi}`, { basePath: window.env.FLIGHTS_API_BASE_URL },
);

export const deleteFlightTag = (gufi, tag) => callApi(
  `${FlightsPaths.FLIGHTS}/${gufi}/tags/${tag}`, { basePath: window.env.FLIGHTS_API_BASE_URL, method: 'DELETE' },
);

export default queryFlightsListData;
