import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { timestampToHHmm, convertHHmmToHHmmWithColon, xor } from '@atfm/utils';
import AutoComplete from '../AutoComplete/AutoComplete';

import { selectIsPretactSessionActive } from '../../store/pretact/selectors';
import doNothing from '../../utils/Common';

import './SectoConfig.css';

const editDeletebuttons = (listId, entryId, isEditing, deleteEntry, changeEditStatus) => (
  <Fragment>
    <div className="options-buttons">
      <div className="edit-delete-buttons">
        {!isEditing &&
        <button id="editButton" className="no-bg-button" onClick={() => changeEditStatus(listId, entryId, true)} type="button">
          <i className="material-icons edit-discard-icons">edit</i>
        </button>
        }
      </div>
      <div className="edit-delete-buttons">
        <button id="deleteButton" className="no-bg-button" onClick={(e) => { e.stopPropagation(); deleteEntry(listId, entryId); }} type="button">
          <i className="material-icons edit-discard-icons">delete</i>
        </button>
      </div>
    </div>
  </Fragment>
);

const editingButtons = (listId, entryId, isValid, confirmEdit, changeEditStatus) => (
  <Fragment>
    <button
      data-testid="btn-ok"
      className="button ok-button"
      onClick={(e) => { e.stopPropagation(); confirmEdit(listId, entryId); }}
      disabled={!isValid}
      type="button"
    >
      OK
    </button>
    <button
      data-testid="btn-cancel"
      className="cancel-button"
      onClick={() => changeEditStatus(listId, entryId, false)}
      type="button"
    >
      Cancel
    </button>
  </Fragment>
);

const divClassName = (isEditing, isClicked, isPastConf, isValidFrom, isValidTo) => {
  const classname = 'config-container';
  if (isPastConf) {
    return `${classname} past-config`;
  }
  if (isEditing || isClicked) {
    return `${classname} ${isClicked ? 'clicked-config' : ''} ${isEditing ? 'editing-config' : ''} ${(!isValidFrom || !isValidTo) ? 'validation-expand' : ''}`;
  }
  return `${classname} future-config`;
};

const isOnlyOneTimeInvalid = (validFrom, validTo) =>
  (xor(validFrom, validTo) ? 'single-validation-message-pos' : '');

const sectoConfigComponent = ({
  listId, entryId, entry, possibleConfs, changeEditStatus, changeClickStatus, changeFrom, changeTo, changeConfig, deleteEntry, confirmEdit,
}) => {
  const isPretactSessionActive = useSelector(selectIsPretactSessionActive);

  return (
    <div className="sectoconf-wrapper">
      <div
        role="button"
        className={divClassName(entry.isEditing, entry.isClicked, entry.isPastConf, entry.validFrom, entry.validTo)}
        onClick={() => { changeClickStatus(listId, entryId); }}
        onKeyDown={doNothing}
        disabled={false}
      >
        <AutoComplete
          name="confName"
          className="config-select"
          classNamePrefix="config-select"
          disabled={!entry.isEditing}
          value={entry.currentData.conf_name}
          onChange={event => changeConfig(listId, entryId, event.target.value)}
          options={possibleConfs.map(conf => (
            { value: conf, label: conf }
          ))}
        />
        {!isPretactSessionActive && entry.isCurrent ?
          <div className="active-grid">
            <span className="current-declaration-status">ACTIVE</span>
            <div className="oval" />
          </div> :
          <div />
        }
        <div className="input-values">
          <span>FROM</span>
          <input
            type="text"
            placeholder="HH:mm"
            value={entry.validFrom ? convertHHmmToHHmmWithColon(timestampToHHmm(entry.currentData.from)) : entry.currentData.from}
            disabled={!entry.isEditing}
            onChange={event => changeFrom(listId, entryId, event.target.value)}
            className={entry.validFrom ? undefined : 'invalid'}
          />
        </div>
        <div className="input-values">
          <span>TO</span>
          <input
            type="text"
            placeholder="HH:mm"
            value={entry.validTo ? convertHHmmToHHmmWithColon(timestampToHHmm(entry.currentData.to)) : entry.currentData.to}
            disabled={!entry.isEditing}
            onChange={event => changeTo(listId, entryId, event.target.value)}
            className={entry.validTo ? undefined : 'invalid'}
          />
        </div>
        {!entry.isPastConf && editDeletebuttons(listId, entryId, entry.isEditing, deleteEntry, changeEditStatus)}
        {entry.isEditing && editingButtons(listId, entryId, entry.isValid, confirmEdit, changeEditStatus)}
      </div>
      <div className="validation">
        {(!entry.validTo || !entry.validFrom) &&
          <label className={`validation-message-bar validation-message-color ${isOnlyOneTimeInvalid(entry.validFrom, entry.validTo)}`}>
            <div>
              {!entry.validFrom && `FROM: ${entry.validFromMessage}`}
            </div>
            <div>
              {!entry.validTo && `TO: ${entry.validToMessage}`}
            </div>
          </label>
        }
      </div>
    </div>
  );
};

sectoConfigComponent.propTypes = {
  listId: PropTypes.number.isRequired,
  entryId: PropTypes.number.isRequired,
  entry: PropTypes.shape({
    isClicked: PropTypes.bool,
    isCurrent: PropTypes.bool,
    isValid: PropTypes.bool,
    validFrom: PropTypes.bool,
    validTo: PropTypes.bool,
    validFromMessage: PropTypes.string,
    validToMessage: PropTypes.string,
    isEditing: PropTypes.bool,
    isPastConf: PropTypes.bool,
    isHovering: PropTypes.bool,
    localData: PropTypes.shape({
      conf_name: PropTypes.string,
      from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    currentData: PropTypes.shape({
      conf_name: PropTypes.string,
      from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
  possibleConfs: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeEditStatus: PropTypes.func.isRequired,
  changeClickStatus: PropTypes.func.isRequired,
  changeFrom: PropTypes.func.isRequired,
  changeTo: PropTypes.func.isRequired,
  changeConfig: PropTypes.func.isRequired,
  deleteEntry: PropTypes.func.isRequired,
  confirmEdit: PropTypes.func.isRequired,
};

export default sectoConfigComponent;
