import {
  LOADING_NOTAM_DATA,
  LOADING_NOTAM_DATA_SUCCESS,
  LOADING_NOTAM_DATA_FAILURE,
} from './actions';

const initial = {
  isLoading: false,
};
export default (state = initial, action = {}) => {
  switch (action.type) {
    case LOADING_NOTAM_DATA: {
      return { ...state, isLoading: true };
    }
    case LOADING_NOTAM_DATA_SUCCESS: {
      return { ...state, data: action.data, isLoading: false };
    }
    case LOADING_NOTAM_DATA_FAILURE: {
      return { ...state, error: action.error, isLoading: false };
    }
    default: {
      return state;
    }
  }
};
