import { LateralReroutingModeStatus } from '../../constants';
import { updateForecastFabCe } from '../forecast/actions';
import { queryAirportsData, queryFabCeData, queryFabCeSector, queryRouteData, queryTrajectoriesData } from './services';

export const TOGGLE_RDT_WEATHER = 'TOGGLE_RDT_WEATHER';
export function toggleRdtWeather() {
  return {
    type: TOGGLE_RDT_WEATHER,
  };
}

export const TOGGLE_TS_WEATHER = 'TOGGLE_TS_WEATHER';
export function toggleTSWeather() {
  return {
    type: TOGGLE_TS_WEATHER,
  };
}

export const SET_VISIBLE_TS_WEATHER_SEVERITY_LEVEL = 'SET_VISIBLE_TS_WEATHER_SEVERITY_LEVEL';
export function setTSWeatherSeverityLevel(severity) {
  return {
    type: SET_VISIBLE_TS_WEATHER_SEVERITY_LEVEL,
    severity,
  };
}

export const TOGGLE_TURB_WEATHER = 'TOGGLE_TURB_WEATHER';
export function toggleTurbWeather() {
  return {
    type: TOGGLE_TURB_WEATHER,
  };
}

export const SET_VISIBLE_TURB_WEATHER_SEVERITY_LEVEL = 'SET_VISIBLE_TURB_WEATHER_SEVERITY_LEVEL';
export function setTurbWeatherSeverityLevel(severity) {
  return {
    type: SET_VISIBLE_TURB_WEATHER_SEVERITY_LEVEL,
    severity,
  };
}

export const SET_VISIBLE_RDT_WEATHER_SEVERITY_LEVEL = 'SET_VISIBLE_RDT_WEATHER_SEVERITY_LEVEL';
export function setRdtWeatherSeverityLevel(severity) {
  return {
    type: SET_VISIBLE_RDT_WEATHER_SEVERITY_LEVEL,
    severity,
  };
}

export const TOGGLE_CAT_WEATHER = 'TOGGLE_CAT_WEATHER';
export function toggleCatWeather() {
  return {
    type: TOGGLE_CAT_WEATHER,
  };
}

export const SET_VISIBLE_CAT_WEATHER_SEVERITY_LEVEL = 'SET_VISIBLE_CAT_WEATHER_SEVERITY_LEVEL';
export function setCatWeatherSeverityLevel(severity) {
  return {
    type: SET_VISIBLE_CAT_WEATHER_SEVERITY_LEVEL,
    severity,
  };
}

export const TOGGLE_FAB_CE = 'TOGGLE_FAB_CE';
export function toggleFabCe() {
  return {
    type: TOGGLE_FAB_CE,
  };
}

export const TOGGLE_NFA = 'TOGGLE_NFA';
export function toggleNFA(active) {
  return {
    type: TOGGLE_NFA,
    value: active,
  };
}

export const TOGGLE_FLOW = 'TOGGLE_FLOW';
export function toggleFlow(active) {
  return {
    type: TOGGLE_FLOW,
    value: active,
  };
}

export const TOGGLE_ROUTE = 'TOGGLE_ROUTE';
export function toggleRoute(active) {
  return {
    type: TOGGLE_ROUTE,
    value: active,
  };
}

export const LOADING_ROUTE_DATA = 'LOADING_ROUTE_DATA';
export function loadingRouteData() {
  return {
    type: LOADING_ROUTE_DATA,
  };
}

export const LOADING_ROUTE_DATA_SUCCESS = 'LOADING_ROUTE_DATA_SUCCESS';
export function loadingRouteDataSuccess(routeData, userArea) {
  return {
    type: LOADING_ROUTE_DATA_SUCCESS,
    routeData,
    userArea,
  };
}

export const LOADING_ROUTE_DATA_FAILURE = 'LOADING_ROUTE_DATA_FAILURE';
export function loadingRouteDataFailure(error) {
  return {
    type: LOADING_ROUTE_DATA_FAILURE,
    error,
  };
}

export const LOADING_TRAJECTORY_AIRPORTS_DATA = 'LOADING_TRAJECTORY_AIRPORTS_DATA';
export function loadingTrajectoryAirportsData() {
  return {
    type: LOADING_TRAJECTORY_AIRPORTS_DATA,
  };
}

export const LOADING_TRAJECTORY_AIRPORTS_DATA_SUCCESS = 'LOADING_TRAJECTORY_AIRPORTS_DATA_SUCCESS';
export function loadingTrajectoryAirportsDataSuccess(data, gufi) {
  return {
    type: LOADING_TRAJECTORY_AIRPORTS_DATA_SUCCESS,
    data,
    gufi,
  };
}

export const LOADING_TRAJECTORY_AIRPORTS_DATA_FAILURE = 'LOADING_TRAJECTORY_AIRPORTS_DATA_FAILURE';
export function loadingTrajectoryAirportsDataFailure(error) {
  return {
    type: LOADING_TRAJECTORY_AIRPORTS_DATA_FAILURE,
    error,
  };
}

export const LOADING_FAB_CE_DATA = 'LOADING_FAB_CE_DATA';
export function loadingFabCeData() {
  return {
    type: LOADING_FAB_CE_DATA,
  };
}

export const LOADING_FAB_CE_DATA_SUCCESS = 'LOADING_FAB_CE_DATA_SUCCESS';
export function loadingFabCeDataSuccess(fabCeData) {
  return {
    type: LOADING_FAB_CE_DATA_SUCCESS,
    fabCeData,
  };
}

export const LOADING_FAB_CE_DATA_FAILURE = 'LOADING_FAB_CE_DATA_FAILURE';
export function loadingFabCeDataFailure(error) {
  return {
    type: LOADING_FAB_CE_DATA_FAILURE,
    error,
  };
}

export const LOADING_FAB_CE_SECTOR = 'LOADING_FAB_CE_SECTOR';
export function loadingFabCeSector() {
  return {
    type: LOADING_FAB_CE_SECTOR,
  };
}

export const LOADING_FAB_CE_SECTOR_SUCCESS = 'LOADING_FAB_CE_SECTOR_SUCCESS';
export function loadingFabCeSectorSuccess(geoData, sector, verticalSector, fabCeConvection, timeStart, timeEnd) {
  return {
    type: LOADING_FAB_CE_SECTOR_SUCCESS,
    geoData,
    sector,
    verticalSector,
    fabCeConvection,
    timeStart,
    timeEnd,
  };
}

export const LOADING_FAB_CE_SECTOR_FAILURE = 'LOADING_FAB_CE_SECTOR_FAILURE';
export function loadingFabCeSectorFailure(error) {
  return {
    type: LOADING_FAB_CE_SECTOR_FAILURE,
    error,
  };
}

export const CLEAR_FAB_CE_SECTOR = 'CLEAR_FAB_CE_SECTOR';
export function clearFabCeSector() {
  return {
    type: CLEAR_FAB_CE_SECTOR,
  };
}

export const MARKER_CLICKED = 'MARKER_CLICKED';
export function markerClicked(gufi) {
  return {
    type: MARKER_CLICKED,
    gufi,
  };
}

export const FLIGHT_LIST_ROW_CLICKED = 'FLIGHT_LIST_ROW_CLICKED';
export function rowClicked(gufi) {
  return {
    type: FLIGHT_LIST_ROW_CLICKED,
    gufi,
  };
}

export const FLIGHT_LIST_ROW_EXPANDED = 'FLIGHT_LIST_ROW_EXPANDED';
export function rowExpanded(gufi) {
  return {
    type: FLIGHT_LIST_ROW_EXPANDED,
    gufi,
  };
}

export const TOGGLE_LATERAL_REROUTING_MODE = 'TOGGLE_LATERAL_REROUTING_MODE';
export function toggleLateralReroutingMode() {
  return {
    type: TOGGLE_LATERAL_REROUTING_MODE,
  };
}

export const UPDATE_LATERAL_REROUTING_DATA = 'UPDATE_LATERAL_REROUTING_DATA';

export function updateLateralReroutingData(data) {
  return {
    type: UPDATE_LATERAL_REROUTING_DATA,
    data,
  };
}

export function clearLateralReroutingData() {
  return updateLateralReroutingData({
    status: LateralReroutingModeStatus.NEW,
    newTrajectorySegment: [],
    drawnTrajectory: [],
    measureId: -1,
    gufi: '',
  });
}

/* Async Action Creators */
export const fetchTrajectoryAirportsData = (gufi, airportDep, airportDes, sessionId) => (dispatch) => {
  dispatch(loadingTrajectoryAirportsData());
  return Promise.allSettled([
    queryAirportsData(airportDep), queryAirportsData(airportDes), queryTrajectoriesData(gufi, sessionId),
  ]).then((results) => {
    const data = results.map(item => item.value);
    const formatedData = {
      adep: data[0],
      ades: data[1],
      trajectory: data[2],
    };
    dispatch(loadingTrajectoryAirportsDataSuccess(formatedData, gufi));
  }).catch((error) => {
    dispatch(loadingTrajectoryAirportsDataFailure(error));
  });
};

export const fetchRouteData = (fir, day, userArea) => (dispatch) => {
  dispatch(loadingRouteData());
  return queryRouteData(fir, day, userArea)
    .then(data => dispatch(loadingRouteDataSuccess(data, userArea)))
    .catch(error => dispatch(loadingRouteDataFailure(error)));
};

export const fetchFabCeData = fir => (dispatch) => {
  dispatch(loadingFabCeData());
  return queryFabCeData(fir)
    .then(data => dispatch(loadingFabCeDataSuccess(data)))
    .catch(error => dispatch(loadingFabCeDataFailure(error)));
};

export const fetchFabCeSector = (fir, sector, verticalSector, fabCeConvection, timeStart, timeEnd) => (dispatch) => {
  dispatch(loadingFabCeSector());
  return queryFabCeSector(fir, sector)
    .then(data => dispatch(loadingFabCeSectorSuccess(data, sector, verticalSector, fabCeConvection, timeStart, timeEnd))
      && dispatch(updateForecastFabCe(timeStart)))
    .catch(error => dispatch(loadingFabCeSectorFailure(error)));
};
