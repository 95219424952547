import moment from 'moment';
import { startPretactSession } from '../pretact/reducers';
import {
  LOADING_TIME_DATA,
  LOADING_TIME_DATA_SUCCESS,
  LOADING_TIME_DATA_FAILURE,
  UPDATE_VERSION,
  REPLAY_SESSION_RUN,
  REPLAY_SESSION_PAUSE,
  REPLAY_SESSION_STOP,
} from './actions';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case LOADING_TIME_DATA: {
      return { ...state, loading: true };
    }
    case LOADING_TIME_DATA_SUCCESS: {
      if (!state.loading) {
        // loading was set to false after the request was made, so we ignore the response
        return state;
      }
      return { ...state, loading: false, currTime: action.timeData };
    }
    case LOADING_TIME_DATA_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }
    case UPDATE_VERSION: {
      return {
        ...state,
        version: action.data.version,
      };
    }
    case REPLAY_SESSION_RUN: {
      const { time, startedAt } = action.payload;
      return {
        ...state,
        loading: false,
        currTime: {
          status: 'RUNNING',
          deltaTime: -(startedAt - time),
          time,
        },
      };
    }
    case REPLAY_SESSION_PAUSE: {
      return {
        ...state,
        loading: false,
        currTime: {
          status: 'PAUSED',
        },
      };
    }
    case REPLAY_SESSION_STOP: {
      const { time, startedAt } = action.payload;
      return {
        ...state,
        loading: false,
        currTime: {
          status: 'FIXED',
          deltaTime: -(startedAt - time),
          time,
        },
      };
    }
    case startPretactSession.type: {
      const { date, time, startedAt } = action.payload;
      return {
        ...state,
        currTime: {
          status: 'FIXED',
          deltaTime: -(startedAt - time),
          time: moment(date).startOf('day')
            .set({
              hour: moment(time).get('hour'),
              minute: moment(time).get('minute'),
            })
            .valueOf(),
        },
      };
    }

    default: {
      return state;
    }
  }
};
