import callApi from '../api/services';
import { AirspacesPaths } from '../../constants';

const queryAirspacesData = airspaceName =>
  callApi(
    `${AirspacesPaths.AIRSPACE}${airspaceName}?format=geojson`,
    { basePath: window.env.AIRSPACES_API_BASE_URL },
  );

export default queryAirspacesData;
