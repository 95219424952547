import {
  LOADING_RDT_DATA,
  LOADING_RDT_DATA_SUCCESS,
  LOADING_RDT_DATA_FAILURE,
} from './actions';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case LOADING_RDT_DATA: {
      return state;
    }
    case LOADING_RDT_DATA_SUCCESS: {
      return { ...state, rdtData: action.rdtData };
    }
    case LOADING_RDT_DATA_FAILURE: {
      return { ...state, error: action.error };
    }
    default: {
      return state;
    }
  }
};
