/* eslint-disable no-param-reassign */
import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const JobConsumers = {
  FLIGHTS: 'FLIGHTS',
  TRAFFIC_LOAD_OCC: 'TRAFFIC_LOAD_OCC',
  TRAFFIC_LOAD_ENTRY: 'TRAFFIC_LOAD_ENTRY',
  HOTSPOT_LOAD: 'HOTSPOT_LOAD',
  TIMELINE: 'TIMELINE',
};

const WaitForJob = {
  reset() {
    return Object.keys(JobConsumers).reduce(
      (prev, key) => ({ ...prev, [key]: true }),
      {},
    );
  },
  shouldWait(value, consumer) {
    return value[consumer];
  },
  consume(value, consumer) {
    value[consumer] = consumer === JobConsumers.TIMELINE;
    return value;
  },
};

const TmiContext = createContext();

export const useTmiDefaultContext = () => {
  const [undoLrPoint, setUndoLrPoint] = useState(false);
  const [waitForJob, setWaitForJob] = useState({});

  return {
    undoLrPoint,
    setUndoLrPoint,
    job: {
      reset: () => {
        setWaitForJob(WaitForJob.reset());
      },
      shouldWait(consumer) {
        const should = WaitForJob.shouldWait(waitForJob, consumer);
        const newValue = WaitForJob.consume(waitForJob, consumer);
        setWaitForJob(newValue);
        return !!should;
      },
    },
    __debug__: process.env.NODE_ENV === 'development' ? { waitForJob, consumers: JobConsumers } : undefined,
  };
};

export const TmiProvider = ({ children }) => {
  const context = useTmiDefaultContext();
  return (
    <TmiContext.Provider value={context}>
      {children}
    </TmiContext.Provider>
  );
};

TmiProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default TmiContext;
export const useTmiContext = () => useContext(TmiContext);
