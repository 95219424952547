import {
  LOADING_FLIGHT_DATA,
  LOADING_FLIGHT_DATA_FAILURE,
  LOADING_FLIGHT_DATA_SUCCESS,
} from './actions';

const initial = { data: [] };
export default (state = initial, action = {}) => {
  switch (action.type) {
    case LOADING_FLIGHT_DATA: {
      return state;
    }
    case LOADING_FLIGHT_DATA_FAILURE: {
      return { ...state, error: action.error };
    }
    case LOADING_FLIGHT_DATA_SUCCESS: {
      return { ...state, data: action.flightData };
    }
    default: {
      return state;
    }
  }
};
