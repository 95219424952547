import queryStams from './services';

export const LOADING_STAMS = 'LOADING_STAMS';
export function loadingStams() {
  return {
    type: LOADING_STAMS,
  };
}

export const LOADING_STAMS_SUCCESS = 'LOADING_STAMS_SUCCESS';
export function loadingStamsSuccess(data) {
  return {
    type: LOADING_STAMS_SUCCESS,
    data,
  };
}

export const LOADING_STAMS_FAILURE = 'LOADING_STAMS_FAILURE';
export function loadingStamsFailure(error) {
  return {
    type: LOADING_STAMS_FAILURE,
    error,
  };
}

export const fetchStams = (stammed, from, to) => (dispatch) => {
  dispatch(loadingStams());

  return queryStams(stammed, from, to)
    .then((response) => {
      dispatch(loadingStamsSuccess(response));
    }).catch((err) => {
      dispatch(loadingStamsFailure(err));
    });
};
