import {
  LOADING_STAMS,
  LOADING_STAMS_SUCCESS,
  LOADING_STAMS_FAILURE,
} from './actions';

const initial = {
  stams: [],
  isLoading: false,
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case LOADING_STAMS: {
      return { ...state, isLoading: true };
    }
    case LOADING_STAMS_SUCCESS: {
      return {
        ...state,
        stams: action.data,
        isLoading: false,
      };
    }
    case LOADING_STAMS_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};
