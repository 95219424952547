/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchBackendLicenses, fetchHmiLicenses } from './actions';

const initialState = {
  hmi: {}, // key: depencency name, value: dependency details
  backend: [], // array of strings
};

const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  extraReducers: {
    [fetchHmiLicenses.fulfilled]: (state, { payload }) => {
      state.hmi = payload;
    },
    [fetchBackendLicenses.fulfilled]: (state, { payload }) => {
      state.backend = payload.licenses;
    },
  },
});

export default licensesSlice.reducer;
