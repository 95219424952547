import {
  LOADING_FLOW_DATA,
  LOADING_FLOW_DATA_SUCCESS,
  LOADING_FLOW_DATA_FAILURE,
  CREATING_FLOW_DATA,
  CREATING_FLOW_DATA_SUCCESS,
  CREATING_FLOW_DATA_FAILURE,
  UPDATING_FLOW_DATA_SUCCESS,
  UPDATING_FLOW_DATA_FAILURE,
  UPDATING_FLOW_DATA,
  DELETE_FLOW_DATA_SUCCESS,
  DELETE_FLOW_DATA,
  DELETE_FLOW_DATA_FAILURE,
} from './actions';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case LOADING_FLOW_DATA: {
      return state;
    }
    case LOADING_FLOW_DATA_SUCCESS: {
      return {
        ...state,
        flows: action.flow.sort((a, b) => (a.id - b.id > 0 ? 1 : -1)),
      };
    }
    case LOADING_FLOW_DATA_FAILURE: {
      return { ...state, error: action.error };
    }
    case CREATING_FLOW_DATA: {
      return { ...state, isLoading: action.isLoading };
    }
    case CREATING_FLOW_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: action.isLoading,
        flows: [...state.flows, action.flow],
      };
    }
    case CREATING_FLOW_DATA_FAILURE: {
      return { ...state, isLoading: action.isLoading, error: action.error };
    }
    case UPDATING_FLOW_DATA: {
      return { ...state, isLoading: action.isLoading };
    }
    case UPDATING_FLOW_DATA_SUCCESS: {
      const filteredFlow = state.flows.filter(flow => flow.id !== action.flow.id);
      return {
        ...state,
        isLoading: action.isLoading,
        flows: [...filteredFlow, action.flow].sort((a, b) => (a.id - b.id > 0 ? 1 : -1)),
      };
    }
    case UPDATING_FLOW_DATA_FAILURE: {
      return { ...state, error: action.error, isLoading: action.isLoading };
    }
    case DELETE_FLOW_DATA: {
      return { ...state, isLoading: action.isLoading };
    }
    case DELETE_FLOW_DATA_SUCCESS: {
      const filteredFlow = state.flows.filter(flow => flow.id !== action.id);
      return {
        ...state,
        isLoading: action.isLoading,
        flows: filteredFlow,
      };
    }
    case DELETE_FLOW_DATA_FAILURE: {
      return { ...state, isLoading: action.isLoading, error: action.error };
    }
    default: {
      return state;
    }
  }
};
