import callApi from '../api/services';
import { WhatifPaths } from '../../constants';

export const fetchGetWhatif = sessionId =>
  callApi(`${WhatifPaths.WHATIFS}/${sessionId}`, { basePath: window.env.WHATIF_API_BASE_URL });

export const fetchPutWhatif = (sessionId, createdAt, eventType) => {
  const params = [];

  if (sessionId) {
    params.push(`copyOf=${sessionId}`);
  }

  if (createdAt) {
    params.push(`createdAt=${createdAt}`);
  }

  const url = params.length > 0 ? `${WhatifPaths.WHATIFS}?${params.join('&')}` : WhatifPaths.WHATIFS;

  return callApi(
    url,
    {
      basePath: window.env.WHATIF_API_BASE_URL,
      method: 'PUT',
      body: {
        type: eventType || 'NONE',
      },
    });
};

export const fetchDeleteWhatif = sessionId => callApi(`${WhatifPaths.WHATIFS}/${sessionId}`, { basePath: window.env.WHATIF_API_BASE_URL, method: 'DELETE' });

export const fetchAddMeasure = (sessionId, measure, measureType) => callApi(
  `${WhatifPaths.WHATIFS}/${sessionId}${WhatifPaths.MEASURE}/${measureType}`,
  { basePath: window.env.WHATIF_API_BASE_URL, method: 'POST', body: measure },
);

export const fetchDeleteMeasure = (measureId, measureType) =>
  callApi(
    `${WhatifPaths.WHATIFS}${WhatifPaths.MEASURE}/${measureType.toLowerCase()}/${measureId}`,
    { basePath: window.env.WHATIF_API_BASE_URL, method: 'DELETE' },
  );

export const fetchUpdateMeasure = (updatedFields, measureId, measureType) => callApi(
  `${WhatifPaths.WHATIFS}${WhatifPaths.MEASURE}/${measureType.toLowerCase()}/${measureId}`,
  { basePath: window.env.WHATIF_API_BASE_URL, method: 'PATCH', body: updatedFields },
);

export const fetchProposeStam = sessionId =>
  callApi(`${WhatifPaths.WHATIFS}/${sessionId}${WhatifPaths.IMPLEMENT}`, { basePath: window.env.WHATIF_API_BASE_URL, method: 'POST' });

export const fetchSimulationsList = (from, to) =>
  callApi(`${WhatifPaths.WHATIFS}?from=${from}&to=${to}`, { basePath: window.env.WHATIF_API_BASE_URL });

export const fetchComputeNewTrajectory = (gufi, callsign, trajectory, type) => callApi(`${WhatifPaths.WHATIFS}${WhatifPaths.NEW_TRAJECTORY}/${type.toLowerCase()}`,
  { basePath: window.env.WHATIF_API_BASE_URL,
    method: 'POST',
    body: {
      gufi,
      callsign,
      trajectory,
      type,
    } },
);
