import callApi from '../api/services';
import { KpisPaths } from '../../constants';

export const queryKpisData = (firName, currentTime, horizon) => {
  let params = '';
  if (horizon) {
    params = `${params}&horizon=${horizon}`;
  }
  return callApi(`${KpisPaths.KPIS}?fir=${firName}&time=${currentTime}${params}`, { basePath: window.env.KPIS_API_BASE_URL })
    .then(data => data);
};

export default queryKpisData;
